import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import formattedMoney from '../../../util/FormatteMoney/index';
import { api_multipague_configuracao } from '../../../services/api';

import Wrapper from '../../../components/Wrapper';
import DataGrid from '../../../components/DataGrid';
import Box from '../../../components/Box';
import { Button, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';

export default function AssociacaoContasConsulta() {
  const history = useHistory();
  const theme = useTheme();
  const cnpj1 = localStorage.getItem('cnpj');
  const id1 = localStorage.getItem('idConsulta');
  const razaoSocial1 = localStorage.getItem('razaoSocial');
  const conta = localStorage.getItem('conta');
  const tipoControle = localStorage.getItem('tipoControle');

  const [loadButton, setLoadButton] = useState(false);

  const [editId, setEditId] = useState(id1);

  const columns = [
    { field: 'id', headerName: 'Evento', flex: 1, minWidth: 100 },
    { field: 'descricao', headerName: 'Descrição', flex: 3, minWidth: 200 },
    { field: 'utilizaEsteira', headerName: 'Esteira', flex: 1, minWidth: 100 },
    {
      field: 'valorMinimo',
      headerName: 'Valor Mínimo',
      flex: 2,
      minWidth: 100,
    },
    {
      field: 'valorMaximo',
      headerName: 'Valor Máximo',
      flex: 2,
      minWidth: 100,
    },
    {
      field: 'valorTarifa',
      headerName: 'Valor Tarifa',
      flex: 2,
      minWidth: 100,
    },
    {
      field: 'valorCusto',
      headerName: 'Valor Custos',
      flex: 2,
      minWidth: 100,
    },
    {
      field: 'editar',
      headerName: 'Editar',
      type: 'text',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      renderCell: event => {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleEditar(event.row);
            }}
            style={{ borderRadius: '20px' }}
          >
            <EditIcon
              style={{ cursor: 'pointer' }}
              color={theme.palette.primary.main}
              size={20}
            />
          </Button>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);

  function novoRegistro() {
    history.push('/backoffice/associacao-contas-cadastro');
  }
  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URL = `/eventoClienteConta/lista?idClienteConta=${editId}`;

  useEffect(() => {
    setLoadButton(true);
    api_multipague_configuracao
      .get(URL, { headers: { Authorization: AuthStr } })
      .then(response => {
        setLoadButton(false);
        console.log('Aqui ó Will: ', response.data);
        const monitorFilter = response.data;

        const aquiTable = monitorFilter.map(data => ({
          ...data,
          id: data.id,
          descricao: data.evento.descricao,
          valorMaximo: formattedMoney(parseFloat(data.valorMaximo)),
          valorMinimo: formattedMoney(parseFloat(data.valorMinimo)),
          valorCusto: formattedMoney(parseFloat(data.valorMinimo)) || 0,
          valorTarifa:
            data.valorTarifa != null
              ? formattedMoney(parseFloat(data.valorTarifa))
              : formattedMoney(parseFloat(0)),
          utilizaEsteira: data.utilizaEsteira === true ? "Sim" : data.utilizaEsteira === false ? 'Não' : null,
        }));
        setRows(aquiTable);
      })
      .catch(error => {
        setLoadButton(false);
        error.response.status == 404
          ? toastComponentSuccess(error.response.data.mensagem, theme)
          : toastComponentError(error.response.data.mensagem, theme);
      });
  }, []);

  const handleEditar = value => {
    const formatedValorTar =
      value.valorTarifa != null
        ? value.valorTarifa.replace(/[^0-9]+/g, '').slice(0, -2)
        : 0;
        const formatedValorCusto =
      value.valorCusto != null
        ? value.valorCusto.replace(/[^0-9]+/g, '').slice(0, -2)
        : 0;
    const formatedValorMax = value.valorMaximo.replace(/[^0-9]+/g, '');
    const formatedValorMin = value.valorMinimo.replace(/[^0-9]+/g, '');
    localStorage.setItem('ass-contas-consulta-codigo', value.id);
    localStorage.setItem('ass-contas-consulta-faixa', value.faixaTarifa);
    localStorage.setItem('ass-contas-consulta-codigoEvento', value.evento.id);
    localStorage.setItem(
      'ass-contas-consulta-identificadorEvento',
      value.evento.eventoIdentificador,
    );
    localStorage.setItem(
      'ass-contas-consulta-clienteConta',
      value.evento.clienteConta.id,
    );
    localStorage.setItem(
      'ass-contas-consulta-descricao',
      value.evento.descricao,
    );
    localStorage.setItem('ass-contas-consulta-vlrMinimo', formatedValorMin);
    localStorage.setItem('ass-contas-consulta-vlrMaximo', formatedValorMax);
    localStorage.setItem('ass-contas-consulta-vlrTarifa', formatedValorTar);
    localStorage.setItem('ass-contas-consulta-vlrCusto', formatedValorCusto);
    localStorage.setItem(
      'ass-contas-consulta-esteira',
      value.utilizaEsteira == 'Sim' ? true : false,
    );
    history.push('/backoffice/associacao-contas-editar');
  };

  return (
    <Wrapper
      backButton
      title="Consulta"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        {
          link: '/backoffice/associacao-contas-lista',
          name: 'Associação de Contas',
        },
      ]}
      newRegister={novoRegistro}
    >
      <Box width={100} style={{ borderRadius: '20px' }}>
        <Grid container spacing={3} style={{ margin: '20px 0', width: '100%' }}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              display: 'flex',
              width: '100%',
              border: `1px solid ${theme.palette.divider}`,
              justifyContent: 'center',
              textAlign: 'center',
              borderRadius: '10px',
              flexDirection: 'column',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              variant="body2"
              style={{ fontWeight: '700', lineHeight: '180%' }}
            >
              CNPJ: <span style={{ fontWeight: '500' }}>{cnpj1}</span>
            </Typography>
            <Typography
              variant="body2"
              style={{ fontWeight: '700', lineHeight: '180%' }}
            >
              Razão Social:{' '}
              <span style={{ fontWeight: '500' }}>{razaoSocial1}</span>
            </Typography>
            <Typography
              variant="body2"
              style={{ fontWeight: '700', lineHeight: '180%' }}
            >
              Conta: <span style={{ fontWeight: '500' }}>{conta}</span>
            </Typography>
            <Typography
              variant="body2"
              style={{ fontWeight: '700', lineHeight: '180%' }}
            >
              Controle: <span style={{ fontWeight: '500' }}>{tipoControle === "null" ? "" : tipoControle}</span>
            </Typography>
          </Grid>
        </Grid>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loadButton}
          height={'660px'}
          pageSize={10}
        />
      </Box>
    </Wrapper>
  );
}
