import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import 'react-toastify/dist/ReactToastify.css';
import DataGridWrapper from './../../../components/DataGrid/DataGridWrapper';
import { Button, Chip, Grid, TextField } from '@material-ui/core';
import { toastComponentError, toastComponentSuccess } from './../../../components/Toast';
import { api_multipague_configuracao, api_multipague_tarifador } from '../../../services/api';
import DateComponent from '../../../components/InputDate';
import moment from 'moment';
import formattedMoney from '../../../util/FormatteMoney';
 
import { downloadCSV } from '../../../utils';
import Loading from '../../../components/Loading';
import AutocompleteClienteConta from '../../../components/AutocompleteClienteConta';

export default function TarifadorMonitorConta() {
    const USER_TOKEN = localStorage.getItem('tkn-access');
    const AuthStr = 'Bearer '.concat(USER_TOKEN);

  const history = useHistory();
  const theme = useTheme();

  const [rows, setRows] = useState([]);

  const [loadButton, setLoadButton] = useState(true);
  const [loadPage, setLoadPage] = useState(false);
  
  const ultimoPeriodo = moment().subtract(1, 'months').format('YYYY-MM');
  const paramsInicial = {
    periodo: ultimoPeriodo,
    idClienteConta:0,
  }

  const [params,setParams] = React.useState(paramsInicial);
  const refAutocomplete = React.useRef();
  

  const columns = [
    { field: 'id', headerName: 'Código', minWidth: 100, },
    { 
      field: 'conta', 
      headerName: 'Agência - Conta', 
      minWidth: 150,
      renderCell: value => {
        return <p>{`${value.row?.agencia} ${value.row?.conta}-${value.row?.digitoConta}`} </p>;
      }, 
    },
    { 
      field: 'evento', 
      headerName: 'Evento', 
      minWidth: 300,
      renderCell: value => {
        return <p>{`${value.row?.evento}`} </p>;
      }, 
    },
    { 
      field: 'tipoApuracao', 
      headerName: 'Tipo Apuração', 
      minWidth: 150,
      renderCell: value => {
        return value.row.apuracaoMultiplica === true ? "Multiplica" : "Banco";
      },
    },
    { field: 'quantidade', headerName: 'Quantidade',  minWidth: 150,align:"center", },
    { 
      field: 'tarifa', 
      headerName: 'Valor Tarifa',  
      minWidth: 120,
      align:"center",
      renderCell: value => {
        return <p>{formattedMoney(parseFloat(value.row.tarifa || 0))} </p>;
      }, 
    },
    { 
      field: 'total', 
      headerName: 'Total',  
      minWidth: 120,
      renderCell: value => {
        return <p>{formattedMoney(parseFloat(value.row.subTotal || 0))} </p>;
      }, 
    },
   
  ];

  const carregarMonitorConta = (filtro,page=0,size=10)=>{
    const URL = `/apuracao-tarifa/detalhes-conta-periodo`;
    const [ano,mes] = filtro.periodo.split("-");
    const body = {
      mes:mes.padStart(2, '0'),
      ano,
    }

    if(!!filtro.idClienteConta){
      body["idClienteConta"] = filtro.idClienteConta;
    }

    setLoadButton(true);
    api_multipague_tarifador
      .get(URL, { headers: { Authorization: AuthStr },params:body })
      .then(response => {
        setLoadButton(false);
        const monitorFilter = response.data;
        if (monitorFilter.length) {
          setRows(monitorFilter.map((item,idx)=>({...item,id:idx + 1})));
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }


  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <AutocompleteClienteConta 
            ref={refAutocomplete}
            onChange={(item)=>{
              setParams(old=>({...old,idClienteConta:item?.value}))
            }} 
          />
            
        ),
        grid: { xs: 12, sm: 8, md: 6, lg: 6 },
      },
      {
        html: () => (
          <DateComponent
            callback={value =>{
              if(!!value){
                setParams(old=>({...old,periodo:value}))
              }else{
                setParams(old=>({...old,periodo:ultimoPeriodo}))
              }
            }}
            label="Selecionar Período"
            id="data-periodo-inicio-filtro"
            defaultValue={params.periodo}
            value={params.periodo}

            type={"month"}
            inputProps={{
              max: ultimoPeriodo
            }}
          />
        ),
        grid: { xs: 12, sm: 4, md: 3, lg: 3 },
      },
       
    ],
    searchButton: {
      searchButton: () => carregarMonitorConta(params),
    },
    cleanButton: {
      cleanButton: () => {
        setParams(paramsInicial);
        if(refAutocomplete?.current){
          refAutocomplete?.current?.reset();
        }
        carregarMonitorConta(paramsInicial);
      },
    },
  };

  const handleExportarCSV = ()=>{
    setLoadPage(true);
    const URL = `/apuracao-tarifa/gerar-csv-detalhes-conta-periodo`;

    const [ano,mes] = params.periodo.split("-");
    const body = {
      mes:mes.padStart(2, '0'),
      ano,
    }
    if(!!params.idClienteConta){
      body["idClienteConta"] = params.idClienteConta;
    }

    api_multipague_tarifador
    .get(URL,{params:body,responseType: "blob",})
    .then(async({data})=>{
      await downloadCSV(data,"Tarifador-monitor-por-conta.csv")
      toastComponentSuccess('Tarifador Monitor Conta exportado com sucesso!', theme);
    }).catch((error)=>{
      toastComponentError(error.response.data.mensagem, theme);
    }).finally(()=>{
      setLoadPage(false)
    })
  }

  useEffect(() => {
    carregarMonitorConta(paramsInicial)
  }, []);

  
  return (
    <>
    <DataGridWrapper
        headerComponent={() => (
          <Grid container spacing={2} style={{marginTop:"1rem"}}>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              style={{
                display: 'flex',
                marginLeft: '6.8px',
              }}
              >
              <Chip
                variant="variant"
                label="Exportar CSV"
                color="secondary"
                onClick={handleExportarCSV}
                />
            </Grid>
          </Grid>
        )}
        formData={filterInputs}
        minHeight="600px"
        rows={rows}
        columns={columns}
        loading={loadButton}
        pageSize={10}
        backButton
        title="Monitor Tarifador por Conta"
        crumb={[{ link: '/backoffice', name: 'Home' }, { name: 'Tarifador' }]}
        newRegister={undefined}
        />
        <Loading show={loadPage}/>
    </>
  );
}
