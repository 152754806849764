import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import 'react-toastify/dist/ReactToastify.css';

import { api_multipague_configuracao,api_multipague_conta } from '../../../services/api';
import { MdArrowBack, MdSave } from 'react-icons/md';

import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import {AutocompleteOptions } from "./style";
import { FormControlLabel, Switch } from '@material-ui/core';

export default function CadastroFavorecidoTarifador() {
  const history = useHistory();
  const theme = useTheme();
  const {id} = useParams();
  const location = useLocation();
 
  const [loadButton, setLoadButton] = useState(false);
  const [favorecidoSelecionado, setFavorecidoSelecionado] = useState("");
  const [form, setForm] = useState({
    idFavorecido:"",
    favorecidoPadrao:false
  });
  const [favorecidos, setFavorecidos] = useState([]);
 
 
  const getFavorecidos = ()=>{
    api_multipague_configuracao.get("/favorecido/listar").then(({data})=>{
      const format = !!data ? data.map(item=>({label:item[0],value:item[1]})) : [];
      if(!!id){
        const {favorecido,favorecidoPadrao} = location.state;
        if(!!favorecido){
          setForm(old=>({
            ...old,
            idFavorecido:favorecido.id,
            favorecidoPadrao
          }))
          setFavorecidoSelecionado(format.find(item=>item.value === favorecido.id));
        }
      }
      setFavorecidos(format);
    })
  }

  React.useEffect(()=>{
    getFavorecidos();
  },[])

  const handleSalvar = () => {
    setLoadButton(true);

    const action = !!id ? api_multipague_configuracao.put : api_multipague_configuracao.post;
    
    action( !!id ? `/favorecido-tarifador/${id}` : `/favorecido-tarifador`, form)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Cadastro feito com sucesso!', theme);
        history.push('/backoffice/favorecido-tarifador-lista');
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  return (
    <Wrapper
      backButton
      title="Cadastrar Favorecido Tarifador"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        {
          link: '/backoffice/favorecido-tarifador-lista',
          name: 'Favorecido Tarifador',
        },
      ]}
      crumbActive="Cadastro"
    >
      <Box style={{ borderRadius: '20px' }}>
        <div style={{ height: '1rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={2} lg={2} >
            <TextField
              fullWidth
              label="Código"
              disabled
              id="outlined-size-small"
              value={id || ""}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
              <AutocompleteOptions
                id="favorecido"
                options={favorecidos}
                getOptionLabel={(option) => option.label}
                onChange={(evnt,campo)=>{
                  if(!!campo){
                    setForm(old=>({...old,idFavorecido:campo.value}))
                    setFavorecidoSelecionado(campo);
                  }else{
                    setForm(old=>({...old,idFavorecido:undefined}))
                    setFavorecidoSelecionado("");
                  }
                }}
                value={favorecidoSelecionado}
                renderInput={(params) => <TextField {...params} label="Selecione o Favorecido" variant="outlined" InputLabelProps={{ shrink: true }}/>}
              />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <FormControlLabel
                data-testid="switch-expirados"
                control={
                  <Switch
                    onChange={(event,checked) => setForm(old=>({...old,favorecidoPadrao:checked}))}
                    checked={form.favorecidoPadrao}
                  />
                }
                label="Favorecido Padrão"
              />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.push('/backoffice/favorecido-tarifador-lista');
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              !loadButton && handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
