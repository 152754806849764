import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Divider,
  IconButton,
  Popover,
  Paper,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { api_multipague_configuracao } from '../../services/api';
import InputText from '../../components/InputText';
import CPF from '../../components/CPF';
import { Button } from '@material-ui/core';
import Loading from '../../components/Loading';
import { maskCpfCnpj } from '../../util/mask';
import PersonIcon from '@material-ui/icons/Person';
import { Header, ButtonTable, useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { FaEllipsisV } from 'react-icons/fa';

import { Alert } from '@material-ui/lab';
import DataGridWrapper from '../../components/DataGrid/DataGridWrapper';
import { Form } from '@unform/web';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

export const VinculoUsuarios = () => {
  const history = useHistory();
  const theme = useTheme();
  const formRef = useRef(null);
  const [openMenuActions, setOpenMenuActions] = useState(false);
  const [rowId, setRowId] = useState(null);

  function handleEditUser(value) {
    api_multipague_configuracao
      .get(`/usuario/${value.login.replace(/[^0-9]+/g, '')}`)
      .then(function (response) {
        localStorage.setItem(
          'edicao_usuario_vinculo',
          JSON.stringify(response.data),
        );
        history.push('/backoffice/vinculoedit');
      })
      .catch(() => {
        toastComponentError('Não foi possível finalizar a requisição', theme);
      });
  }
  const columnsTable = [
    { field: 'nome', headerName: 'Nome', flex: 1, minWidth: 200 },
    {
      field: 'login',
      headerName: 'CPF',
      type: 'string',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      type: 'string',
      minWidth: 200,
      flex: 1,
      textTransform: 'none',
    },
    {
      field: 'snedemail',
      align: 'center',
      headerName: 'Ações',
      sortable: false,
      minWidth: 100,
      disableClickEventBubbling: true,
      renderCell: event => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = event => {
          setAnchorEl(event.currentTarget);
          setOpenMenuActions(true);
        };

        const handleClose = () => {
          setAnchorEl(null);
          setOpenMenuActions(false);
        };

        const open =
          Boolean(anchorEl) && openMenuActions && event.row.id === rowId;
        const id = open ? 'simple-popover' : undefined;
        return (
          <>
            <IconButton onClick={handleClick} sx={{ mt: 2 }}>
              <FaEllipsisV aria-describedby={id} variant="contained" />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
            >
              <Box
                style={{
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Tooltip
                  title="Clientes Vinculados"
                  aria-label="contas-vinculadas"
                >
                  <ButtonTable
                    style={{ color: theme.palette.primary.main }}
                    onClick={() => {
                      handleOpenModal(event.row);
                    }}
                  >
                    <AccountBalanceWalletIcon
                      size={24}
                      color={theme.palette.primary.main}
                    />
                  </ButtonTable>
                </Tooltip>

                <Tooltip title="Vincular Usuário" aria-label="vincular-usuario">
                  <ButtonTable
                    onClick={() => {
                      handleEditUser(event.row);
                    }}
                    style={{ color: theme.palette.primary.main }}
                  >
                    <PersonIcon size={24} color={theme.palette.primary.main} />
                  </ButtonTable>
                </Tooltip>
              </Box>
            </Popover>
          </>
        );
      },
    },
  ];

  const [rowsTable, setRowsTable] = useState([]);

  const [loadingTable, setLoadingTable] = useState(false);

  const [loadingScreen, setLoadingScreen] = useState(false);

  const [open, setOpen] = useState(false);

  const [parametroUser, setParametroUser] = useState();

  const [vinculadas, setVinculadas] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  function handleOpenModal(event) {
    api_multipague_configuracao
      .get(
        `/usuario/${event.login.replace(
          /[^0-9]+/g,
          '',
        )}/clientes/vinculados/perfil-por-conta`,
      )
      .then(response => {
        const formatted = response.data.map(usr => ({
          ...usr,
          nome: usr.nome,
          perfil: usr.contas.map(item => item.perfil),
        }));
        setVinculadas(formatted);
        setParametroUser(event.login.replace(/[^0-9]+/g, ''));
        setOpen(true);
      })
      .catch(err => {
        setVinculadas([]);
        console.log(err);
      });
  }

  async function listarUsuarios() {
    try {
      setLoadingTable(true);
      const { data } = await api_multipague_configuracao.get(
        '/usuario/login?master=false&dominioMultiplica=false',
      );

      const formatted = data.map(usr => ({
        ...usr,
        nome: usr.nome,
        login: maskCpfCnpj(usr.login),
        email: usr.email,
        situacao: usr.ativo,
        id: usr.id,
      }));
      setRowsTable(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
      console.log(err);
    } finally {
      setLoadingTable(false);
    }
  }
  async function filterUser(body) {
    try {
      setLoadingTable(true);
      const { data } = await api_multipague_configuracao.get(
        `usuario/login?master=false&nome=${body.nome}&username=${
          !!body.cpf ? body.cpf.replace(/[^\d]/g, '') : ''
        }`,
      );

      const formatted = data.map(usr => ({
        ...usr,
        nome: usr.nome,
        login: maskCpfCnpj(usr.login),
        email: usr.email,
        situacao: usr.ativo,
        id: usr.id,
      }));
      setRowsTable(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
      console.log(err);
    } finally {
      setLoadingTable(false);
    }
  }

  useEffect(() => {
    listarUsuarios();
  }, []);

  function handleBloquear(vinculada) {
    const { data } = api_multipague_configuracao
      .put(
        `/usuario/cliente/${vinculada.cliente.id}/${parametroUser}/${
          vinculada.ativo == true ? 'desativar' : 'ativar'
        }`,
      )
      .then(function (response) {
        toastComponentSuccess('Solicitação efetuada!', theme);
        setOpen(false);
      })
      .catch(function (error) {
        // setLoadButton(false);
        console.log(error);
        if (error.response.status == 400 || error.response.status == 404) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <InputText id="nome" name="nome" label="Nome" variant="outlined" />
        ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
      {
        html: () => <CPF name="cpf" id="cpf" variant="outlined" label="CPF" />,
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
    ],
    searchButton: {
      searchButton: () => filterUser(formRef?.current?.getData()),
    },
    cleanButton: {
      cleanButton: () => {
        formRef?.current?.setData({ nome: '', cpf: '' });
        filterUser(formRef?.current?.getData());
      },
    },
  };

  return (
    <>
      <Loading show={loadingScreen} />

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Clientes vinculados</DialogTitle>
        <Divider />
        <DialogContent style={{ width: 'auto' }}>
          <List dense style={{ minWidth: '400px', overflow: 'auto' }}>
            {vinculadas.length > 0 ? (
              vinculadas.map(vinculada => (
                <Paper variant="outlined" style={{ marginBottom: '1rem' }}>
                  <ListItem
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    key={vinculada.id}
                  >
                    <Typography style={{ padding: '.5rem 0' }} variant="body2">
                      {vinculada.cliente.nome}
                    </Typography>
                    <Button>
                      {vinculada.ativo == true ? (
                        <Tooltip
                          title="Bloquear acesso"
                          aria-label="contas-vinculadas"
                        >
                          <LockOpenIcon
                            color="secondary"
                            onClick={() => handleBloquear(vinculada)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Desbloquear acesso"
                          aria-label="contas-vinculadas"
                        >
                          <LockIcon
                            color="secondary"
                            onClick={() => handleBloquear(vinculada)}
                          />
                        </Tooltip>
                      )}
                    </Button>
                  </ListItem>
                  <Divider />
                  <List dense style={{ padding: '.5rem 1rem 0' }}>
                    <ListItemText>
                      {vinculada.contas.map(item => (
                        <>
                          <ListItemText
                            secondary={
                              <Typography variant="p">{item.perfil}</Typography>
                            }
                          />
                          <ListItemText
                            style={{ paddingBottom: '.5rem' }}
                            secondary={`Banco: ${item.conta.nomeBanco} / Conta: ${item.conta.conta}`}
                          />
                        </>
                      ))}
                    </ListItemText>
                  </List>
                </Paper>
              ))
            ) : (
              <ListItemText>
                <Alert color="info" icon={false}>
                  Nenhum cliente vinculado
                </Alert>
              </ListItemText>
            )}
          </List>
        </DialogContent>
      </Dialog>

      <Form ref={formRef} onSubmit={filterUser}>
        <DataGridWrapper
          minHeight="600px"
          rows={rowsTable}
          columns={columnsTable}
          pageSize={10}
          backButton
          onRowClick={params => setRowId(params.id)}
          title="Vínculo de Usuário"
          crumb={[
            { link: '/backoffice/new-backoffice', name: 'Home' },
            { name: 'Vínculos' },
          ]}
          loading={loadingTable}
          formData={filterInputs}
        />
      </Form>
    </>
  );
};
