import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import { Cookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';

import { MdArrowBack, MdSave } from 'react-icons/md';

import { api_multipague_configuracao } from '../../../services/api';

import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';

export default function CadastroEventos() {
  const history = useHistory();
  const theme = useTheme();
  const editCodigo = localStorage.getItem('codigo');
  const editDescricao = localStorage.getItem('descricao');
  const editIdentificadorEvento = localStorage.getItem('identificadorEvento');
  const editEsteira = localStorage.getItem('esteira');
  const editSenha = localStorage.getItem('senha');
  const editAtivo = localStorage.getItem('ativo');
  const [loadButton, setLoadButton] = useState(false);

  const [selecionarConta, setSelecionarConta] = useState([]);

  const [contaPadrao, setContaPadrao] = useState(editIdentificadorEvento);
  const [descricao, setDescricao] = useState(editDescricao);
  const [selectContas, setSelectContas] = useState('');
  const [codigo, setCodigo] = useState(editCodigo);

  const [esteira, setEsteira] = React.useState({
    checkedA: JSON.parse(editEsteira),
  });
  const [state, setState] = React.useState({
    checkedA: JSON.parse(editSenha),
  });
  const [active, setActive] = React.useState({
    checkedA: JSON.parse(editAtivo),
  });
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);
  const editaCadastro = {
    ativo: active.checkedA,
    descricao: descricao,
    identificadorEvento:
      selectContas === '' ? editIdentificadorEvento : selectContas.toString(),
    id: parseInt(codigo),
    utilizaEsteira: esteira.checkedA,
    utilizaSenha: state.checkedA,
  };

  useEffect(() => {
    api_multipague_configuracao
      .get(`/identificador-evento`)
      .then(response => {
        setSelecionarConta(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .put(`/evento`, editaCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Informação editada com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/eventos-lista');
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const handleCodigo = event => {
    setCodigo(event.target.value);
  };
  const handleEsteira = event => {
    setEsteira({ ...esteira, [event.target.name]: event.target.checked });
  };
  const handleSenha = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleAtivo = event => {
    setActive({ ...active, [event.target.name]: event.target.checked });
  };

  const handleDescricao = event => {
    setDescricao(event.target.value);
  };

  return (
    <Wrapper
      backButton
      title={`Editar evento`}
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/eventos-lista', name: 'Eventos' },
      ]}
    >
      <Box style={{ borderRadius: '20px', padding: 30 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={1} lg={1}>
            <TextField
              fullWidth
              label="Código"
              id="outlined-size-small"
              value={codigo}
              onChange={handleCodigo}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Descrição"
              id="outlined-size-small"
              value={descricao}
              onChange={handleDescricao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={selecionarConta}
              getOptionLabel={option => option.descricao}
              onChange={(event, option) => {
                setSelectContas(option.valor);
              }}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Identificador do Evento"
                  placeholder={contaPadrao}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <FormLabel component="legend">Esteira</FormLabel>
            <CustomSwitch
              checked={esteira.checkedA}
              onChange={handleEsteira}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <FormLabel component="legend">Senha</FormLabel>
            <CustomSwitch
              checked={state.checkedA}
              onChange={handleSenha}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <FormLabel component="legend">Ativo</FormLabel>
            <CustomSwitch
              checked={active.checkedA}
              onChange={handleAtivo}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.goBack();
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
