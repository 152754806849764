import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import 'react-toastify/dist/ReactToastify.css';

import { MdArrowBack, MdSave, MdSearch } from 'react-icons/md';

import Box from '../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import {
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import Wrapper from '../../../components/Wrapper';
import { getEvents, getEventsService, saveEventsService } from '../services';
import { useStyles } from '../styles';

export default function CadastroSegmentoTarifador() {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const historyState = JSON.parse(localStorage.getItem('servicos'));
  const [loadButton, setLoadButton] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const [loadingEventos, setLoadingEventos] = useState(false);
  const [eventoOptions, setEventoOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filtroEventos, setFiltroEventos] = useState('');
  const [sendFiltrosEventos, setSendFiltrosEventos] = useState('');

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setChecked([]);
    } else {
      setChecked(eventoOptions.map(event => event.id));
    }
    setSelectAll(!selectAll);
  };

  const handleSalvar = () => {
    setLoadButton(true);
    saveEventsService(
      historyState?.id,
      eventoOptions
        .filter(item => checked.includes(item.id))
        .map(item => item.id),
    )
      .then(() => {
        setLoadButton(false);
        toastComponentSuccess('Cadastro feito com sucesso!', theme);
        history.push('/backoffice/servicos-editar');
      })
      .catch(error => {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const getEventos = () => {
    getEventsService(historyState?.id)
      .then(data => {
        setChecked(data.map(item => item.id));
      })
      .catch(error => {
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
    setLoadingEventos(true);
    getEvents()
      .then(data => {
        setLoadingEventos(false);
        setEventoOptions(data);
      })
      .catch(error => {
        setLoadingEventos(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  useEffect(() => {
    getEventos();
  }, []);

  useEffect(() => {
    const filteredEventos = eventoOptions.filter(item =>
      item?.descricao
        ?.toLowerCase()
        .includes(sendFiltrosEventos?.toLowerCase()),
    );

    if (!!sendFiltrosEventos) {
      setEventoOptions(filteredEventos);
    } else {
      getEventos();
    }
  }, [sendFiltrosEventos]);

  return (
    <Wrapper
      backButton
      title="Vincular Evento"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/servicos', name: 'Serviços' },
        { link: '/backoffice/servicos-editar', name: 'Editar Serviço' },
      ]}
      crumbActive="Cadastro"
    >
      <Box className={classes.wrapperBox}>
        <Grid container className={classes.gridPadding}>
          <Grid item md={12} className={classes.gridFlex}>
            <Grid className={classes.gridTypography}>
              <Typography variant="body1" style={{ fontSize: 15 }}>
                Serviço:
              </Typography>
              <Typography
                style={{ fontWeight: '600', marginLeft: 5, fontSize: 15 }}
                variant="body1"
              >
                {historyState?.descricao}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '20px 0 10px',
          }}
        >
          <Grid item md={1} sm={2}>
            <Typography
              variant="body1"
              className={classes.typographySize}
              style={{ fontSize: 15 }}
            >
              Eventos:
            </Typography>
          </Grid>
          <Grid item md={4} style={{ marginLeft: 5 }} xs={12}>
            <TextField
              value={filtroEventos}
              disabled={eventoOptions.length <= 0}
              onChange={e => {
                setFiltroEventos(e.target.value);
              }}
              variant="outlined"
              fullWidth
              label="Buscar evento"
              InputLabelProps={{ shrink: true }}
              size="small"
              InputProps={{
                style: { borderRadius: '20px' },
                endAdornment: (
                  <IconButton
                    style={{ padding: '5px' }}
                    disabled={eventoOptions.length <= 0}
                    onClick={() => setSendFiltrosEventos(filtroEventos)}
                  >
                    <MdSearch
                      style={{ cursor: 'pointer' }}
                      color="inherit"
                      size={20}
                    />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                eventoOptions.length === checked.length ? true : selectAll
              }
              onChange={handleSelectAll}
            />
          }
          label="Selecionar todos"
          edge="start"
          className={classes.selectAllCheckbox}
          disableRipple
        />
        <Grid container spacing={3} className={classes.divServicosBorder}>
          {loadingEventos ? (
            <CircularProgress style={{ marginTop: 30, marginLeft: 15 }} />
          ) : (
            Array(2)
              .fill()
              .map((_, index) => (
                <Grid item xs={12} md={6} key={index} style={{ marginTop: 5 }}>
                  <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {eventoOptions
                      .slice(
                        index === 0 ? 0 : Math.ceil(eventoOptions.length / 2),
                        index === 0
                          ? Math.ceil(eventoOptions.length / 2)
                          : undefined,
                      )
                      .map(value => {
                        const labelId = `checkbox-list-label-${value.id}`;

                        return (
                          <ListItem
                            key={value.id}
                            disablePadding
                            style={{ height: 40, padding: 5 }}
                          >
                            <div
                              role={undefined}
                              className={classes.divListIntern}
                              onClick={handleToggle(value.id)}
                              dense
                            >
                              <ListItemIcon style={{ marginRight: -20 }}>
                                <Checkbox
                                  edge="start"
                                  checked={checked?.indexOf(value.id) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={value.descricao}
                              />
                            </div>
                          </ListItem>
                        );
                      })}
                  </List>
                </Grid>
              ))
          )}
        </Grid>

        <div className={classes.divButtonBackSave}>
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.goBack();
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              !loadButton && handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
