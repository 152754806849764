import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LoadingBtn from '../../components/Buttons/ConfirmButton';
import { api_multipague_configuracao } from '../../services/api';
import Wrapper from '../../components/Wrapper';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Box from '../../components/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { v4 as uuidv4 } from 'uuid';
import {
  alpha,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  hexToRgb,
  makeStyles,
  Paper,
  Switch,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import { MdAdd, MdCancel, MdClear, MdSave, MdVisibility } from 'react-icons/md';
import BoxColor from './BoxColor';
import { useDispatch } from 'react-redux';
import { changePreviewTheme } from '../../store/modules/app';
import { perfilMaster } from '../../util/Auth';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { IoMdEye } from 'react-icons/io';

const useStyles = makeStyles(theme => ({
  iconAddClear: {
    cursor: 'pointer',
    border: `3px solid ${theme.palette.primary.light}`,
    borderRadius: '20px',
    margin: '0 2px 2px',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.light,
  },
  fieldsetStyle: {
    borderColor: theme.palette.grey[400],
    width: '100%',
    borderWidth: '1px',
    borderRadius: '20px',
  },
  legendStyle: {
    fontWeight: 500,
    fontSize: '0.800rem',
    lineHeight: 1.57,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    padding: '0 5px',
  },
  flexChips: {
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  marginFieldset: {
    margin: '0 2px 2px',
  },
  marginFieldsetChip: {
    margin: '0 2px 2px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
  gridFlex: {
    marginTop: '-8px',
  },
  noEmailPaper: {
    padding: '0 5px',
  },
  buttonsBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  buttonsBottomSearchView: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  wrapperBox: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
    padding: '1.5rem',
  },
  buttonsBottomUnit: {
    boxShadow: 'none',
    textTransform: 'initial',
    fontSize: '8px',
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    textTransform: 'capitalize',
  },
  buttonsBottomCancel: {
    boxShadow: 'none',
    textTransform: 'initial',
    fontSize: '8px',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    padding: '6px 15px',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
  },
}));

const ValorLimite = React.memo(({ defaultValue, onChange }) => {
  const [valor, setValor] = useState(0);
  useEffect(() => {
    setValor(defaultValue);
  }, [defaultValue]);
  return (
    <CurrencyTextField
      fullWidth
      size="small"
      label="Valor Limite"
      id="outlined-size-small"
      value={valor}
      onChange={(e, value) => {
        setValor(value);
      }}
      onBlur={() => onChange(valor)}
      variant="outlined"
      decimalCharacter=","
      digitGroupSeparator="."
      currencySymbol="R$"
      outputFormat="number"
    />
  );
});

export default function Parametros() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const temaSalvo = localStorage.getItem('app-config');
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      situacao: 'ATIVADO',
      valorLimite: 0,
      emailNotificacao: '',
      emailNotificacaoAtivado: 'ATIVADO',
      parametroEmail: '',
      theme: JSON.parse(temaSalvo),
      tedAutomaticaTarifador:true
    },
  });

  const [loadButton, setLoadButton] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');

  function novoParametro(values) {
    const parametros = {
      id: 0,
      parametroNotificacaoTransferencia: {
        emailNotificacaoTransferencia: values.parametroEmail,
        notificacaoTransferenciaHabilitada:
          values.situacao === 'ATIVADO' ? true : false,
        valorMinimoNotificacaoTransferencia: values.valorLimite,
      },
      parametroNotificaoAlteracao: {
        emailNotificacao: emails.join(','),
        emailnotificacaoHabilitado:
          values.emailNotificacaoAtivado === 'ATIVADO' ? true : false,
      },
      parametroTema: {
        json: JSON.stringify(values.theme),
      },
      tedAutomaticaTarifador: values.tedAutomaticaTarifador
    };

    setLoadButton(true);
    api_multipague_configuracao
      .put(`/parametros`, parametros)
      .then(function (response) {
        setLoadButton(false);
        toastComponentSuccess('Parâmetro atribuído com sucesso!', theme);
        previewTheme();
        localStorage.setItem('app-config', parametros.parametroTema.json);
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }
  const URL = `/parametros`;

  useEffect(() => {
    api_multipague_configuracao
      .get(URL)
      .then(response => {
        const {
          parametroNotificacaoTransferencia,
          parametroTema,
          parametroNotificaoAlteracao,
          tedAutomaticaTarifador
        } = response.data;

        let theme = JSON.parse(parametroTema.json);
        if (
          !theme.hasOwnProperty('palette') &&
          !theme.hasOwnProperty('config')
        ) {
          theme = JSON.parse(localStorage.getItem('app-config'));
        }

        setValue(
          'parametroEmail',
          parametroNotificacaoTransferencia.emailNotificacaoTransferencia,
        );
        setValue(
          'situacao',
          parametroNotificacaoTransferencia.notificacaoTransferenciaHabilitada
            ? 'ATIVADO'
            : 'DESATIVADO',
        );
        setValue(
          'valorLimite',
          parametroNotificacaoTransferencia.valorMinimoNotificacaoTransferencia,
        );
        setValue(
          'emailNotificacaoAtivado',
          parametroNotificaoAlteracao.emailnotificacaoHabilitado
            ? 'ATIVADO'
            : 'DESATIVADO',
        );
        setEmails(
          parametroNotificaoAlteracao.emailNotificacao
            .split(',')
            .filter(item => item != ''),
        );
        setValue(
          'emailNotificacao',
          parametroNotificaoAlteracao.emailNotificacao,
        );
        setValue( 'tedAutomaticaTarifador', tedAutomaticaTarifador);

        
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const corBgMenu = watch('theme.palette.appBar.light.bgMenu');
  useEffect(() => {
    if (corBgMenu.length > 3) {
      const colorMenu = hexToRgb(corBgMenu);
      const corSubMenu = colorMenu
        .replace(')', ',0.75)')
        .replace('rgb', 'rgba');
      setValue('theme.palette.appBar.light.bgSubMenu', corSubMenu);
    }
  }, [corBgMenu]);

  const previewTheme = () => {
    const tempTheme = getValues('theme');
    dispatch(changePreviewTheme({ ...tempTheme, id: uuidv4() }));
  };

  function validarEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleAddChip = () => {
    if (validarEmail(inputValue)) {
      const newEmails = [...emails];
      newEmails.push(inputValue);
      setEmails(newEmails);
      setInputValue('');
    } else {
    }
  };

  const handleDeleteChip = chipToDelete => {
    const newEmails = emails.filter(chip => chip !== chipToDelete);
    setEmails(newEmails);
  };

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  return (
    <Wrapper
      backButton
      title="Parâmetros"
      crumb={[
        { link: '/backoffice/new-backoffice', name: 'Home' },
        { name: 'Configurações' },
      ]}
    >
      <form onSubmit={e => e.preventDefault()}>
        <Box className={classes.wrapperBox}>
          <Typography variant="body2" style={{ marginBottom: '0px' }}>
            Configurações do sistema
          </Typography>
          <Grid
            container
            spacing={3}
            style={{ marginBottom: '1rem', marginTop: '5px' }}
          >
            <Grid item xs={12} md={2}>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: '100%' }}
              >
                <InputLabel id="">Situação</InputLabel>
                <Select
                  fullWidth
                  label="Situação"
                  id="outlined-size-small2"
                  variant="outlined"
                  size="small"
                  value={watch('situacao')}
                  {...register('situacao')}
                >
                  <MenuItem value="ATIVADO">ATIVADO</MenuItem>
                  <MenuItem value="DESATIVADO">DESATIVADO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <TextField
                fullWidth
                label="Nome do cliente"
                {...register('theme.config.clienteName')}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="Email"
                id="outlined-size-small"
                {...register('parametroEmail')}
                variant="outlined"
                type="email"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <ValorLimite
                defaultValue={watch('valorLimite')}
                onChange={valor => setValue('valorLimite', valor)}
              />
            </Grid>
            <Grid item className={classes.gridFlex}>
              <FormControl variant="outlined" size="small">
                <fieldset
                  value={watch('emailNotificacao')}
                  {...register('emailNotificacao')}
                  className={classes.fieldsetStyle}
                >
                  <legend className={classes.legendStyle}>
                    Emails Para Notificação
                  </legend>
                  <div className={classes.flexChips}>
                    {emails.length === 0 && !showTextField ? (
                      <Paper className={classes.noEmailPaper} elevation={0}>
                        Nenhum e-mail
                      </Paper>
                    ) : (
                      emails.map((chip, index) => (
                        <React.Fragment key={index}>
                          {isXSmallScreen ? (
                            <Tooltip placement={'left-start'} title={chip}>
                              <Chip
                                clickable
                                component={'button'}
                                className={classes.marginFieldsetChip}
                                variant="outlined"
                                label={chip}
                                onDelete={() => handleDeleteChip(chip)}
                              />
                            </Tooltip>
                          ) : (
                            <Chip
                              clickable
                              component={'button'}
                              className={classes.marginFieldsetChip}
                              variant="outlined"
                              label={chip}
                              onDelete={() => handleDeleteChip(chip)}
                            />
                          )}
                        </React.Fragment>
                      ))
                    )}
                    {showTextField ? (
                      <TextField
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={event => {
                          if (event.key === 'Enter') {
                            handleAddChip();
                          }
                        }}
                        type="email"
                        label="Novo Email"
                        variant="outlined"
                        size="small"
                        className={classes.marginFieldset}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ) : null}
                    {!showTextField ? (
                      <MdAdd
                        size={23}
                        className={classes.iconAddClear}
                        onClick={() => setShowTextField(true)}
                      />
                    ) : (
                      <MdClear
                        size={23}
                        className={classes.iconAddClear}
                        onClick={() => setShowTextField(false)}
                      />
                    )}
                  </div>
                </fieldset>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: '100%' }}
              >
                <InputLabel id="">Situação Email Notificação</InputLabel>
                <Select
                  fullWidth
                  disabled={!perfilMaster()}
                  label="Situação Email Notificação"
                  id="outlined-size-small2"
                  variant="outlined"
                  size="small"
                  value={watch('emailNotificacaoAtivado')}
                  {...register('emailNotificacaoAtivado')}
                >
                  <MenuItem value="ATIVADO">ATIVADO</MenuItem>
                  <MenuItem value="DESATIVADO">DESATIVADO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <FormControlLabel control={<Switch checked={watch('tedAutomaticaTarifador')} onChange={(e,checked)=>{
                  setValue("tedAutomaticaTarifador",checked)
                 }} />} label="Gerar TED tarifador automático" />
            </Grid>
          </Grid>
          <Divider />
          <Typography variant="body2" style={{ marginTop: '1rem' }}>
            Configurações do tema
          </Typography>
          <Grid
            container
            spacing={3}
            style={{ marginBottom: '2rem', marginTop: '5px' }}
          >
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="Imagem de Apresentação"
                {...register('theme.config.loginImage')}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="Logo do Login"
                {...register('theme.config.loginLogo')}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="Logo do Login e Seleção de Contas"
                {...register('theme.config.loginLogoLeft')}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="Logo do menu"
                {...register('theme.config.menuLogo')}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body2">Cores do Sistema</Typography>
              <Grid container spacing={2} style={{ marginTop: '5px' }}>
                <Grid item xs={12} sm="6" style={{ display: 'flex' }}>
                  <div style={{ flex: 2 }}>
                    <TextField
                      fullWidth
                      label="Cor Principal"
                      {...register('theme.palette.primary.main')}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <BoxColor
                    onChange={color =>
                      setValue('theme.palette.primary.main', color)
                    }
                    bg={watch('theme.palette.primary.main')}
                    valueForm={watch('theme.palette.primary.main')}
                  />
                </Grid>

                <Grid item xs={12} sm="6" style={{ display: 'flex' }}>
                  <div style={{ flex: 2 }}>
                    <TextField
                      fullWidth
                      label="Cor Secundária"
                      {...register('theme.palette.secondary.main')}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <BoxColor
                    onChange={color =>
                      setValue('theme.palette.secondary.main', color)
                    }
                    bg={watch('theme.palette.secondary.main')}
                    valueForm={watch('theme.palette.secondary.main')}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body2">Menu / Topo</Typography>
              <Grid container spacing={2} style={{ marginTop: '5px' }}>
                <Grid item sx={12} sm={6} style={{ display: 'flex' }}>
                  <div style={{ flex: 2 }}>
                    <TextField
                      fullWidth
                      label="Cor de fundo"
                      {...register('theme.palette.appBar.light.bgMenu')}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <BoxColor
                    onChange={color =>
                      setValue('theme.palette.appBar.light.bgMenu', color)
                    }
                    bg={watch('theme.palette.appBar.light.bgMenu')}
                    valueForm={watch('theme.palette.appBar.light.bgMenu')}
                  />
                </Grid>
                <Grid item sx={12} sm={6} style={{ display: 'flex' }}>
                  <div style={{ flex: 2 }}>
                    <TextField
                      fullWidth
                      label="Cor do texto"
                      InputProps={{
                        readOnly: true,
                      }}
                      {...register('theme.palette.appBar.light.text')}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <BoxColor
                    onChange={color =>
                      setValue('theme.palette.appBar.light.text', color)
                    }
                    bg={watch('theme.palette.appBar.light.bgMenu')}
                    color={watch('theme.palette.appBar.light.text')}
                    text="Menu"
                    valueForm={watch('theme.palette.appBar.light.text')}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body2">Menu Selecionado</Typography>
              <Grid container spacing={2} style={{ marginTop: '5px' }}>
                <Grid item sx={12} sm={6} style={{ display: 'flex' }}>
                  <div style={{ flex: 2 }}>
                    <TextField
                      fullWidth
                      label="Cor do fundo"
                      InputProps={{
                        readOnly: true,
                      }}
                      {...register('theme.palette.appBar.light.bgMenuSelected')}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <BoxColor
                    onChange={color =>
                      setValue(
                        'theme.palette.appBar.light.bgMenuSelected',
                        color,
                      )
                    }
                    bg={watch('theme.palette.appBar.light.bgMenuSelected')}
                    valueForm={watch(
                      'theme.palette.appBar.light.bgMenuSelected',
                    )}
                  />
                </Grid>
                <Grid item sx={12} sm={6} style={{ display: 'flex' }}>
                  <div style={{ flex: 2 }}>
                    <TextField
                      fullWidth
                      label="Cor do texto"
                      InputProps={{
                        readOnly: true,
                      }}
                      {...register('theme.palette.appBar.light.textSelected')}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <BoxColor
                    onChange={color =>
                      setValue('theme.palette.appBar.light.textSelected', color)
                    }
                    bg={watch('theme.palette.appBar.light.bgMenuSelected')}
                    color={watch('theme.palette.appBar.light.textSelected')}
                    text="Menu"
                    valueForm={watch('theme.palette.appBar.light.textSelected')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '2rem' }} />
          <div className={classes.buttonsBottom}>
            <LoadingBtn
              variant="variant"
              color="secondary"
              title="Cancelar"
              onClick={() => window.location.reload()}
              startIcon={<MdCancel color="background" />}
            >
              Cancelar
            </LoadingBtn>
            <div className={classes.buttonsBottomSearchView}>
              <LoadingBtn
                variant="contained"
                color="primary"
                type="button"
                title="Visualizar"
                startIcon={<IoMdEye color="background" />}
                onClick={previewTheme}
              >
                Visualizar
              </LoadingBtn>
              <LoadingBtn
                variant="contained"
                color="primary"
                title={'Salvar'}
                isLoading={loadButton}
                startIcon={!loadButton && <MdSave color="background" />}
                onClick={handleSubmit(novoParametro)}
              >
                Salvar
              </LoadingBtn>
            </div>
          </div>
        </Box>
      </form>
    </Wrapper>
  );
}
