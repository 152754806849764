import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Tooltip,
  alpha,
  Box as MuiBox,
  makeStyles,
} from '@material-ui/core';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@material-ui/icons/Edit';
import { MdAdd, MdArrowBack, MdSave } from 'react-icons/md';

import { api_multipague_configuracao } from '../../../services/api';

import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import DataGrid from '../../../components/DataGrid';
import formattedMoney from '../../../util/FormatteMoney';
import PlusButton from '../../../components/Buttons/PlusButton';

export default function EdicaoTarifador() {
  const history = useHistory();
  const historyState = JSON.parse(localStorage.getItem('tarifador'));
  const theme = useTheme();
  const useStyles = makeStyles(theme => ({
    btnAdd: {
      width: '25px',
      height: '25px',
      borderRadius: '20px',
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.primary.dark, 0.8),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: 'none',
      backgroundColor: alpha(
        theme.palette.appBar[theme.palette.type].bgMenu,
        0.7,
      ),
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
    },
  }));
  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [loadingDatagrid, setLoadingDatagrid] = useState(false);
  const [descricao, setDescricao] = useState(historyState?.descricao);
  const [codigo, setCodigo] = useState(historyState?.id);
  const [rows, setRows] = useState([]);

  const columns = [
    { field: 'id', headerName: 'Código', minWidth: 50 },
    { field: 'idEvento', flex: 1, headerName: 'Evento', minWidth: 180 },
    {
      field: 'idSegmentoTarifador',
      headerName: 'Segmento Tarifador',
      minWidth: 180,
    },
    {
      field: 'apuracaoTarifa',
      headerName: 'Apuração Tarifa',
      minWidth: 150,
    },
    {
      field: 'valorTarifa',
      headerName: 'Valor Tarifa',
      minWidth: 120,
      renderCell: value => {
        return <p>{formattedMoney(parseFloat(value.row.valorTarifa))} </p>;
      },
    },
    {
      field: 'valorCusto',
      headerName: 'Valor Custo',
      minWidth: 120,
      renderCell: value => {
        return <p>{formattedMoney(parseFloat(value.row.valorCusto))} </p>;
      },
    },
    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Editar',
      type: 'text',
      renderCell: value => {
        return (
          <>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                localStorage.setItem(
                  'segmento-tarifador',
                  JSON.stringify(value.row),
                );
                history.push('/backoffice/evento-tarifador-editar');
              }}
            >
              <EditIcon color={theme.palette.primary.main} size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    api_multipague_configuracao
      .get(`/segmento/${historyState?.id}`)
      .then(response => {
        setCodigo(response.data?.id);
        setDescricao(response.data?.descricao);
        localStorage.setItem('tarifador', JSON.stringify(response.data));
      });
    setLoadingDatagrid(true);
    api_multipague_configuracao
      .get(`/segmento/${codigo}/evento`)
      .then(response => {
        const monitorFilter = response.data;
        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.id,
            idSegmentoTarifador: data.segmentoTarifador.descricao,
            idEvento: data.evento.descricao,
            apuracaoTarifa: data.apuracaoTarifa,
            valorTarifa: data.valorTarifa,
            valorCusto: data.valorCusto,
          }));
          setRows(aquiTable);
        } else {
          setRows([]);
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        if (error.response?.status === 404) {
          setRows([]);
          return;
        }
        toastComponentError(
          error.response?.data?.mensagem ||
            'Não foi possível se comunicar com o servidor',
          theme,
        );
      })
      .finally(() => {
        setLoadingDatagrid(false);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .put(`/segmento/${codigo}`, {
        descricao: descricao,
        id: codigo,
      })
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Informação editada com sucesso!', theme);
        history.push('/backoffice/tarifador');
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  return (
    <Wrapper
      backButton
      title={`Editar tarifa`}
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/tarifador', name: 'Segmento Tarifador' },
      ]}
    >
      <Box style={{ borderRadius: '20px', padding: 30 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2} lg={2}>
            <TextField
              fullWidth
              label="Código"
              disabled
              id="outlined-size-small"
              value={codigo}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Descrição"
              id="outlined-size-small"
              value={descricao}
              onChange={event => setDescricao(event?.target?.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid item lg={12} style={{ paddingTop: 50 }}>
          <MuiBox display="flex" alignItems={'center'} pb={1}>
            <Typography
              variant="body1"
              component="h3"
              style={{ marginRight: 5 }}
            >
              Eventos Segmento Tarifador
            </Typography>
            <PlusButton
              title={'Adicionar Evento Tarifador'}
              startIcon={<MdAdd color="background" />}
              onClick={() =>
                history.push('/backoffice/evento-tarifador-cadastro')
              }
            />
          </MuiBox>
          <DataGrid
            columns={columns}
            rows={rows}
            loading={loadingDatagrid}
            autoHeight
            pageSize={10}
          />
        </Grid>
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.push('/backoffice/tarifador');
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              !loadButton && handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
