import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import 'react-toastify/dist/ReactToastify.css';
import { MdArrowBack, MdSave } from 'react-icons/md';
import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import { useTheme } from '@material-ui/core/styles';
import { api_multipague_configuracao } from '../../../services/api';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

export default function CadastroEventos() {
  const history = useHistory();
  const theme = useTheme();
  const bancoNome = localStorage.getItem('assBancoNome');
  const idBanco = localStorage.getItem('assIdBanco');
  const [loadButton, setLoadButton] = useState(false);
  const [eventos, setEventos] = useState([]);
  const [vlrMin, setVlrMin] = useState();
  const [vlrMax, setVlrMax] = useState();
  const [horaMin, setHoraMin] = useState();
  const [horaMax, setHoraMax] = useState();
  const [diasLimite, setDiasLimite] = useState();
  const [idEvento, setidEvento] = useState();
  const [senhaFinanceira, setSenhaFinanceira] = React.useState();

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const novoCadastro = {
    diasCompensacao: 0,
    horaMaximo: horaMax,
    horaMinimo: horaMin,
    idBanco: idBanco,
    idEvento: idEvento,
    quantidadeDiasLimitePagamento: parseInt(diasLimite),
    utilizaSenhaParceiro: Boolean(senhaFinanceira),
    valorMaximo: parseInt(vlrMax),
    valorMinimo: parseInt(vlrMin),
  };

  useEffect(() => {
    api_multipague_configuracao
      .get(`/evento`)
      .then(response => {
        setEventos(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .post(`/eventoBanco`, novoCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Evento associado com sucesso!', theme);
        history.push('/backoffice/associacao-bancos-consulta');
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  return (
    <Wrapper
      backButton
      title="Cadastro"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        {
          link: '/backoffice/associacao-bancos-lista',
          name: 'Associação de Bancos',
        },
        { link: '/backoffice/associacao-bancos-consulta', name: 'Consulta' },
      ]}
    >
      <Box style={{ borderRadius: '20px' }}>
        <div style={{ height: '1rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Agência Padrão"
              id="outlined-size-small"
              value={bancoNome}
              variant="outlined"
              disabled
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              fullWidth
              options={eventos}
              getOptionLabel={option => option.descricao}
              onChange={(_, option) => {
                setidEvento(option.id);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Evento"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              fullWidth
              label="Valor Mínimo"
              variant="outlined"
              size="small"
              value={vlrMin}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(_, value) => setVlrMin(value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              fullWidth
              label="Valor Máximo"
              variant="outlined"
              size="small"
              value={vlrMax}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(_, value) => setVlrMax(value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Horário min permitido para transações"
              id="outlined-size-small"
              type="time"
              value={horaMin}
              onChange={e => {
                setHoraMin(e.target.value);
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                style: {
                  fontSize: '13px',
                },
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Horário max permitido para transações"
              id="outlined-size-small"
              type="time"
              value={horaMax}
              onChange={e => {
                setHoraMax(e.target.value);
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                style: {
                  fontSize: '13px',
                },
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Dias limite de pagamento"
              id="outlined-size-small"
              value={diasLimite}
              onChange={e => {
                setDiasLimite(e.target.value);
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                style: {
                  fontSize: '13px',
                },
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormLabel component="legend">Senha</FormLabel>
            <CustomSwitch
              checked={senhaFinanceira}
              onChange={e => {
                setSenhaFinanceira(e.target.checked);
              }}
              color="primary"
              name="checkedA"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
          <div
            style={{
              margin: '10px',
              width: '100%',
              marginTop: '1rem',
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <LoadingBtn
              variant="contained"
              color="disabled"
              title={'Voltar'}
              startIcon={<MdArrowBack color="background" />}
              onClick={() => history.go(-1)}
            >
              Voltar
            </LoadingBtn>
            <LoadingBtn
              variant="contained"
              color="primary"
              title={'Salvar'}
              isLoading={loadButton}
              startIcon={!loadButton && <MdSave color="background" />}
              onClick={() => {
                handleSalvar();
              }}
            >
              Salvar
            </LoadingBtn>
          </div>
        </Grid>
      </Box>
    </Wrapper>
  );
}
