import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel, Switch, useTheme } from '@material-ui/core';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import 'react-toastify/dist/ReactToastify.css';
import { MdArrowBack, MdSave } from 'react-icons/md';

import { api_multipague_configuracao } from '../../../services/api';

import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';

export default function CadastroEventos() {
  const history = useHistory();
  const theme = useTheme();
  const [loadButton, setLoadButton] = useState(false);

  const [selecionarConta, setSelecionarConta] = useState([]);
  const [descricao, setDescricao] = useState('');
  const [agenciaPadrao, setAgenciaPadrao] = useState('');
  const [especiePadrao, setEspeciePadrao] = useState('');
  const [nossoNumero, setNossoNumero] = useState('');
  const [ispb, setISPB] = useState('');
  const [selectContas, setSelectContas] = useState('');
  const [codigo, setCodigo] = useState('');

  const [state, setState] = React.useState({
    checkedA: false,
  });
  const [active, setActive] = React.useState({
    checkedA: false,
  });
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const novoCadastro = {
    ativo: active.checkedA,
    nome: descricao,
    agenciaPadrao: agenciaPadrao,
    especiePadrao: especiePadrao,
    origemNossoNumero: nossoNumero,
    id: parseInt(codigo),
    bancoMultipague: state.checkedA,
    tipoContaPadrao: selectContas.toString(),
    ispb: ispb,
  };

  useEffect(() => {
    api_multipague_configuracao
      .get(`/tipoConta`)
      .then(response => {
        setSelecionarConta(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .post(`/banco`, novoCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Conta aprovada com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/bancos-lista');
        }, 2000);
      })
      .catch(function (error) {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const handleCodigo = event => {
    setCodigo(event.target.value);
  };

  const handleSenha = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleAtivo = event => {
    setActive({ ...active, [event.target.name]: event.target.checked });
  };

  const handleDescricao = event => {
    setDescricao(event.target.value);
  };
  const handleAgenciaPadrao = event => {
    setAgenciaPadrao(event.target.value);
  };
  const handleEspeciePadrao = event => {
    setEspeciePadrao(event.target.value);
  };
  const handleNossoNumero = event => {
    setNossoNumero(event.target.value);
  };
  const handleISPB = event => {
    const value = event.target.value;
    if (value === '' || /^[0-9]{0,8}$/.test(value)) {
      setISPB(value);
    }
  };

  return (
    <Wrapper
      backButton
      title="Cadastrar banco"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/bancos-lista', name: 'Bancos' },
      ]}
      crumbActive="Cadastro"
    >
      <Box style={{ borderRadius: '20px' }}>
        <div style={{ height: '1rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={2}>
            <FormControlLabel
              label="Ativo"
              style={{ marginLeft: '8px' }}
              labelPlacement="start"
              control={
                <CustomSwitch
                  checked={active.checkedA}
                  onChange={handleAtivo}
                  color="primary"
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} lg={10}>
            <FormControlLabel
              label="Banco Parceiro"
              style={{ marginLeft: '8px' }}
              labelPlacement="start"
              control={
                <CustomSwitch
                  checked={state.checkedA}
                  onChange={handleSenha}
                  color="primary"
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <TextField
              fullWidth
              label="Código"
              id="outlined-size-small"
              onChange={handleCodigo}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Descrição"
              id="outlined-size-small"
              onChange={handleDescricao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Agência Padrão"
              id="outlined-size-small"
              onChange={handleAgenciaPadrao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Espécie Padrão"
              id="outlined-size-small"
              onChange={handleEspeciePadrao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={selecionarConta}
              getOptionLabel={option => option.descricao}
              onChange={(event, option) => {
                setSelectContas(option.id);
              }}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tipo Conta"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Origem Nosso Número"
              id="outlined-size-small"
              value={nossoNumero}
              onChange={handleNossoNumero}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="ISPB"
              id="outlined-size-small"
              value={ispb}
              type="number"
              onChange={handleISPB}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 8,
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.push('/backoffice/bancos-lista');
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
