import moment from 'moment';
import {
  api_multipague_conta,
  api_multipague_conta_ApplicationContext,
} from '../../services/api';
import { maskCNPJ } from '../../util/mask';

const formatDadosSaldos = itemSaldo => {
  const item = {
    id: itemSaldo?.id || itemSaldo.conta,
    cnpj: maskCNPJ(itemSaldo.cliente.cnpjCpf),
    razaoSocial: itemSaldo.cliente.nome,
    dataAbertura: itemSaldo.cliente.dataAberturaConta
      ? moment(itemSaldo.cliente.dataAberturaConta).format('DD/MM/YYYY H:mm:ss')
      : '',
    conta: `${itemSaldo.agencia} ${itemSaldo.conta}-${itemSaldo.digitoConta} `,
    tipoControle: itemSaldo.tipoControle,
    dataUltimaMov: itemSaldo.saldo.dtUltAtualizacao
      ? moment(itemSaldo.saldo.dtUltAtualizacao).format('DD/MM/YYYY H:mm:ss')
      : '',
    saldo: itemSaldo.saldo.saldo,
    error: itemSaldo.saldo.mensagemErro,
  };
  return item;
};

export const getSaldos = async (params, header) => {
  const headers = !!header ? header : { Accept: 'application/paged+json' };

  const { data } = await api_multipague_conta.get('/conta/ativos', {
    params,
    headers,
  });
  const saldos = {
    content: data?.content?.map(item => formatDadosSaldos(item)),
    total: data.totalElements,
  };

  return !!header
    ? {
        content: data?.map(item => formatDadosSaldos(item)),
        total: data.length,
      }
    : saldos;
};

export const downloadPDFCSV = async (razaoSocial, cnpjcpf, tipo) => {
  const cnpjCpf = cnpjcpf.replace(/[.\-\/]/g, '');

  const { data } = await api_multipague_conta_ApplicationContext.post(
    `/relatorios/contas-ativas/emitir-relatorio-filtro?tipoArquivo=${tipo}`,
    {
      razaoSocial,
      cnpjCpf,
    },
  );
  return data;
};

export const downloadPDFCSVSelecionados = async (idsContas, tipo) => {
  const { data } = await api_multipague_conta_ApplicationContext.post(
    `/relatorios/contas-ativas/emitir-relatorio-selecionados?tipoArquivo=${tipo}`,
    idsContas,
  );
  return data;
};
