import React from 'react';
import PropTypes from 'prop-types';
import {
  CssBaseline,
  Breadcrumbs,
  Typography,
  Link,
  useTheme,
  alpha,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TopBar from '../../components/TopBar';
import { ToastContainer } from 'react-toastify';
import { MdAdd } from 'react-icons/md';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  main: {
    flexGrow: 1,
    padding: '0',
    paddingBottom: '0px',
    overflow: 'auto',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.background.dark
        : theme.palette.background.default,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    paddingBottom: '1rem',
    borderRadius: '20px',
    flex: 1,
  },
  contentCopyright: {
    marginTop: '4rem',
  },
  container: {
    borderRadius: '20px',
    height: '100vh',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    padding: theme.spacing(2),
    width: '80vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '20px',
      gap: '.5rem',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '20px',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4, 2, 0),
    width: '80vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    gap: '1rem',
  },
  backtitle: {
    marginLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.6rem',
  },
  btnBack: {
    width: '40px',
    borderRadius: '3px',
    height: '45px',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividerClass: {
    width: '80vw',
    margin: '0.6rem auto',
  },
  buttonAdd: {
    borderRadius: '10px',
    textTransform: 'capitalize',
    padding: '5px 10px',
    colo: alpha(theme.palette.appBar[theme.palette.type].bgMenu, 0.4),
  },
  btnAdd: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    cursor: 'pointer',
    marginTop: '10px',
    backgroundColor: alpha(theme.palette.primary.dark, 0.8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: alpha(
      theme.palette.appBar[theme.palette.type].bgMenu,
      0.7,
    ),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
}));
import { Main } from './style';
import { useHistory } from 'react-router-dom';

const Wrapper = ({
  children,
  title,
  crumb,
  crumbActive,
  newRegister,
  backButton = true,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <TopBar />
      <ToastContainer />
      <Main className={classes.main}>
        <div className={classes.appBarSpacer} />
        <div className={classes.header}>
          <div className={classes.backtitle}>
            {backButton && null}
            <div>
              <Typography variant="inherit" component="h2">
                {title}
              </Typography>
              {crumb && (
                <Hidden smDown>
                  <Breadcrumbs aria-label="breadcrumb">
                    {crumb.map((item, index) => (
                      <Link
                        color="inherit"
                        style={{ cursor: item.link ? 'pointer' : 'default' }}
                        onClick={() => {
                          if (item.link) {
                            history.push(item.link);
                          }
                        }}
                        key={index}
                      >
                        {item.name}
                      </Link>
                    ))}
                    <Typography color="textPrimary">
                      {crumbActive || title}
                    </Typography>
                  </Breadcrumbs>
                </Hidden>
              )}
            </div>
          </div>
          {newRegister && (
            <Tooltip placement="left" title={'Adicionar'}>
              <figure className={classes.btnAdd} onClick={newRegister}>
                <MdAdd size={20} color={useTheme().palette.background.paper} />
              </figure>
            </Tooltip>
          )}
        </div>
        <div className={classes.container}>
          <div className={classes.content}>{children}</div>
          {/* <div className={classes.contentCopyright}> */}
          {/* <Copyright /> */}
          {/* </div> */}
        </div>
      </Main>
    </div>
  );
};

Wrapper.propTypes = {
  title: PropTypes.string.isRequired,
  backButton: PropTypes.bool,
  crumbActive: PropTypes.string,
  crumb: PropTypes.arrayOf({
    link: PropTypes.string,
    name: PropTypes.string,
  }),
  newRegister: PropTypes.func,
  children: PropTypes.element.isRequired,
};
export default Wrapper;
