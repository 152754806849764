import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { api_multipague_configuracao } from '../../../services/api';
import 'react-toastify/dist/ReactToastify.css';
import DataGridWrapper from '../../../components/DataGrid/DataGridWrapper';
import { Button } from '@material-ui/core';
import { toastComponentError } from '../../../components/Toast';

export default function DataGridDemo() {
  const history = useHistory();
  const theme = useTheme();

  const columns = [
    { field: 'id', headerName: 'Código', minWidth: 150 },
    { field: 'nome', headerName: 'Nome', flex: 3, minWidth: 200 },
    {
      field: 'agenciaPadrao',
      headerName: 'Agência Padrão',
      minWidth: 150,
    },
    {
      field: 'ispb',
      headerName: 'ISPB',
      minWidth: 150,
    },
    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Editar',
      type: 'text',
      renderCell: event => {
        return (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                handleEditar(event.row);
              }}
              style={{ borderRadius: '20px' }}
              color="primary"
            >
              <EditIcon color={theme.palette.primary.main} size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [loadButton, setLoadButton] = useState(true);

  function novoRegistro() {
    history.push('/backoffice/bancos-cadastro');
  }
  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URL = `/banco`;

  useEffect(() => {
    setLoadButton(true);
    api_multipague_configuracao
      .get(URL, { headers: { Authorization: AuthStr } })
      .then(response => {
        setLoadButton(false);
        const monitorFilter = response.data;
        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.id,
            descricao: data.nome,
            nome: `${data.id} - ${data.nome}`,
            agenciaPadrao: data.agenciaPadrao,
          }));
          setRows(aquiTable);
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }, []);

  function pesquisar() {
    setLoadButton(true);
    api_multipague_configuracao
      .get(URL)
      .then(response => {
        setLoadButton(false);
        const monitorFilter = response.data;

        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.id,
            nome: data.nome,
            agenciaPadrao: data.agenciaPadrao,
          }));
          setRows(aquiTable);
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }

  const handleEditar = value => {
    localStorage.setItem('banco-codigo', value.id);
    localStorage.setItem('banco-nome', value.descricao);
    localStorage.setItem(
      'banco-agenciaPadrao',
      !!value.agenciaPadrao ? value.agenciaPadrao : '',
    );
    localStorage.setItem('banco-especiePadrao', value.especiePadrao);
    localStorage.setItem('banco-tipoContaPadrao-id', value.tipoContaPadrao?.id);
    localStorage.setItem(
      'banco-tipoContaPadrao-dataCadastro',
      value.tipoContaPadrao?.dataCadastro,
    );
    localStorage.setItem(
      'banco-tipoContaPadrao-usuarioCadastro',
      value.tipoContaPadrao?.usuarioCadastro,
    );
    localStorage.setItem(
      'banco-tipoContaPadrao-descricao',
      value.tipoContaPadrao?.descricao,
    );
    localStorage.setItem(
      'banco-tipoContaPadrao-hibernateLazyInitializer',
      value.tipoContaPadrao?.hibernateLazyInitializer,
    );
    localStorage.setItem('banco-origemNossoNumero', value.origemNossoNumero);
    localStorage.setItem('banco-bancoParceiro', value.bancoMultipague);
    localStorage.setItem('banco-ativo', value.ativo);
    localStorage.setItem('banco-ISPB', value.ispb);
    history.push('/backoffice/bancos-editar');
  };

  return (
    <DataGridWrapper
      minHeight="600px"
      rows={rows}
      columns={columns}
      loading={loadButton}
      pageSize={10}
      backButton
      title="Bancos"
      crumb={[{ link: '/backoffice', name: 'Home' }, { name: 'Configurações' }]}
      newRegister={novoRegistro}
    />
  );
}
