import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import 'react-toastify/dist/ReactToastify.css';

import { api_multipague_configuracao } from '../../../services/api';
import { MdArrowBack, MdSave } from 'react-icons/md';

import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';

export default function CadastroTarifador() {
  const history = useHistory();
  const theme = useTheme();
  const [loadButton, setLoadButton] = useState(false);
  const [descricao, setDescricao] = useState('');

  const handleSalvar = () => {
    setLoadButton(true);

    api_multipague_configuracao
      .post(`/segmento`, { descricao: descricao })
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Cadastro feito com sucesso!', theme);
        history.push('/backoffice/tarifador');
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  return (
    <Wrapper
      backButton
      title="Cadastrar segmento"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/tarifador', name: 'Tarifador' },
      ]}
      crumbActive="Cadastro"
    >
      <Box style={{ borderRadius: '20px' }}>
        <div style={{ height: '1rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              fullWidth
              label="Descrição"
              id="outlined-size-small"
              onChange={event => setDescricao(event?.target?.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.push('/backoffice/tarifador');
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              !loadButton && handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
