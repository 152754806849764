import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { api_multipague_configuracao, api_poke } from '../../services/api';
import Button from '../../components/Buttons/ConfirmButton';
import TextField from '@material-ui/core/TextField';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Select,
  Tooltip,
  alpha,
} from '@material-ui/core';
import Wrapper from '../../components/Wrapper';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { useHistory } from 'react-router-dom';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import { MdFilterList, MdSave } from 'react-icons/md';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: 450,
    height: 230,
    maxHeight: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export const VinculoEdit = () => {
  const history = useHistory();
  const theme = useTheme();
  const editingInfo = JSON.parse(
    localStorage.getItem('edicao_usuario_vinculo'),
  );

  const [nome, setNome] = useState(editingInfo?.nome);
  const [email, setEmail] = useState(editingInfo?.email);
  const [cpf, setCpf] = useState(editingInfo?.login);
  const [ddd, setDdd] = useState(editingInfo?.ddd);
  const [telefone, setTelefone] = useState(editingInfo?.telefone);
  const [perfil, setPerfil] = useState(editingInfo?.perfis[0]);

  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [leftValue, setLeftValue] = React.useState('');
  const [rigthValue, setRigthValue] = React.useState('');
  const [showLeftFilters, setShowLeftFilters] = React.useState(false);
  const [showRightFilters, setShowRightFilters] = React.useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = filteredItems => () => {
    const filteredChecked = intersection(checked, filteredItems);

    if (filteredChecked.length === filteredItems.length) {
      setChecked(not(checked, filteredItems));
    } else {
      setChecked(union(checked, filteredItems));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => {
    const isLeftColumn = title === 'Clientes NÃO vinculados';
    const columnValue = isLeftColumn ? leftValue : rigthValue;
    const setColumnValue = isLeftColumn ? setLeftValue : setRigthValue;
    const showFilters = isLeftColumn ? showLeftFilters : showRightFilters;

    const filteredItems = items.filter(item =>
      item?.nome?.toLowerCase().includes(columnValue?.toLowerCase()),
    );

    const isChecked =
      numberOfChecked(filteredItems) === filteredItems.length &&
      items.length !== 0;
    const isIndeterminate =
      numberOfChecked(filteredItems) !== filteredItems.length &&
      numberOfChecked(filteredItems) !== 0;

    return (
      <Card
        style={{
          border: `solid 1px ${theme.palette.text.disabled}`,
          borderRadius: '20px',
        }}
      >
        <MuiButton
          style={{
            borderRadius: '20px',
            margin: 5,
            padding: '6px 10px',
            textTransform: 'capitalize',
            backgroundColor: isLeftColumn
              ? showLeftFilters
                ? theme.palette.type === 'dark'
                  ? theme.palette.divider
                  : theme.palette.background.default
                : ''
              : showRightFilters
              ? theme.palette.type === 'dark'
                ? theme.palette.divider
                : theme.palette.background.default
              : '',
          }}
          onClick={() =>
            isLeftColumn
              ? setShowLeftFilters(!showLeftFilters)
              : setShowRightFilters(!showRightFilters)
          }
        >
          <MdFilterList size={25} style={{ padding: 2 }} /> Filtros
        </MuiButton>
        {showFilters && (
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'baseline'}
          >
            <TextField
              value={columnValue}
              onChange={e => setColumnValue(e.target.value)}
              id="nomeCliente"
              style={{
                margin: '0 2% 2%',
              }}
              fullWidth
              label="Nome"
              InputLabelProps={{ shrink: true }}
              size="small"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
            />
          </Box>
        )}
        <Divider />
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(filteredItems)}
              checked={isChecked}
              indeterminate={isIndeterminate}
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'todos os itens selecionados' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(filteredItems)}/${
            items.length
          } selecionado(s)`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {filteredItems.map(value => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.nome} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  useEffect(() => {
    api_multipague_configuracao
      .get(
        `/usuario/${editingInfo?.login.replace(
          /[^0-9]+/g,
          '',
        )}/clientes/desvinculados`,
      )
      .then(response => {
        const formatted = response.data.map(usr => ({
          ...usr,
        }));
        setLeft(formatted);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    api_multipague_configuracao
      .get(
        `/usuario/${editingInfo?.login.replace(
          /[^0-9]+/g,
          '',
        )}/clientes/vinculados`,
      )
      .then(response => {
        const formattedCliente = response.data.map(usr => ({
          nome: usr.cliente.nome,
          cnpjCpf: usr.cliente.cnpjCpf,
          dataAberturaConta: usr.cliente.dataAberturaConta,
          dataAceiteTermo: usr.cliente.dataAceiteTermo,
          dataAprovacao: usr.cliente.dataAprovacao,
          dataSolicitacao: usr.cliente.dataSolicitacao,
          id: usr.cliente.id,
          situacao: usr.cliente.situacao,
        }));
        setRight(formattedCliente);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  function handleVincular() {
    api_multipague_configuracao
      .put(`/usuario/${cpf.replace(/[^0-9]+/g, '')}/multipla-associacao`, right)
      .then(function (response) {
        setLoadButton(false);
        toastComponentSuccess('Vínculo feito com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/vinculouser');
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status == 400 || error.response.status == 404) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }

  return (
    <Wrapper
      backButton
      title="Vincular Usuários"
      crumb={[
        { link: '/backoffice/new-backoffice', name: 'Home' },
        { link: '/backoffice/vinculouser', name: 'Vinculo de Usuário' },
      ]}
    >
      <Paper
        style={{
          padding: '1.5rem',
          borderRadius: '20px',
          marginTop: '1.5rem',
          boxShadow: `0px 8px 45px -15px ${alpha(
            theme.palette.primary.dark,
            0.2,
          )}`,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              disabled
              style={{ width: '100%' }}
              id="nome"
              label="Nome Completo"
              variant="outlined"
              value={nome}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              disabled
              style={{ width: '100%' }}
              id="usuario"
              variant="outlined"
              label="CPF"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              value={cpf}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              disabled
              style={{ width: '100%' }}
              id="ddd"
              label="DDD"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              variant="outlined"
              inputProps={{ maxLength: 2 }}
              value={ddd}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              disabled
              style={{ width: '100%' }}
              id="telefone"
              label="Telefone"
              variant="outlined"
              type="text"
              inputProps={{ maxLength: 9, inputMode: 'numeric' }}
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              value={telefone}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              disabled
              style={{ width: '100%' }}
              id="email"
              label="E-mail"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              variant="outlined"
              value={email}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              disabled
              style={{ width: '100%' }}
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
              id="perfil"
              label="Perfil"
              variant="outlined"
              value={perfil}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{
            marginTop: '30px',
          }}
        >
          <Grid item>{customList('Clientes NÃO vinculados', left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Tooltip title={`Vincular clientes selecionados`}>
                <MuiButton
                  variant="contained"
                  color={'secondary'}
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </MuiButton>
              </Tooltip>
              <Tooltip title={`Desvincular clientes selecionados`}>
                <MuiButton
                  variant="contained"
                  size="small"
                  color={'secondary'}
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt; {rightChecked.length}
                </MuiButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item>{customList('Clientes vinculados', right)}</Grid>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            marginBottom: '1rem',
            gap: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={false}
            title={'Confirmar'}
            onClick={() => handleVincular()}
            width={115}
            style={{
              borderRadius: '20px',
              boxShadow: `${alpha(
                theme.palette.primary.dark,
                0.2,
              )} 0px 0px 45px -15px`,
            }}
            loading={loadButton}
            startIcon={<MdSave color="background" />}
          ></Button>
        </Grid>
      </Paper>
    </Wrapper>
  );
};
