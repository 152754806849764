import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Typography,
  makeStyles,
  alpha,
  Divider,
} from '@material-ui/core';
import LoadingBtn from '../../../../components/Buttons/ConfirmButton';
import { MdAdd, MdArrowBack, MdSave } from 'react-icons/md';

import { api_multipague_configuracao } from '../../../../services/api';

import Wrapper from '../../../../components/Wrapper';
import Box from '../../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../../components/Toast';
import formattedMoney from '../../../../util/FormatteMoney';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

export default function EdicaoFaixaTarifador() {
  const history = useHistory();
  const historyState = JSON.parse(localStorage.getItem('faixa-tarifador'));
  const theme = useTheme();
  const useStyles = makeStyles(theme => ({
    buttons: {
      marginTop: '1rem',
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    },
    boxContainer: {
      borderRadius: '20px',
      padding: 30,
    },
  }));
  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [segmentoTarifadorDefault, setSegmentoTarifadorDefault] = useState({
    id: historyState?.id,
    descricao: historyState?.descricao,
    segmentoTarifador: historyState?.segmentoTarifador?.descricao,
    evento: historyState?.evento?.descricao,
    idEvento: historyState?.evento?.id,
    faixaFinal: historyState?.faixaFinal,
    faixaInicial: historyState?.faixaInicial,
    valorTarifa: historyState?.valorTarifa,
    valorCusto: historyState?.valorCusto,
  });
  const [rows, setRows] = useState([]);

  useEffect(() => {
    api_multipague_configuracao
      .get(
        `/segmento/%7BidSegmento%7D/evento/${segmentoTarifadorDefault.id}/faixa`,
      )
      .then(response => {
        const monitorFilter = response.data;
        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.id,
            idSegmentoTarifadorEvento:
              data.segmentoTarifadorEvento.evento.descricao,
            descricao: data.descricao,
            faixaInicial: data.faixaInicial,
            faixaFinal: data.faixaFinal,
            valorTarifa: data.valorTarifa,
            valorCusto: data.valorCusto,
          }));
          setRows(aquiTable);
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      });
    const rows2 = rows
      .filter(
        item => item.idSegmentoTarifadorEvento === segmentoTarifadorDefault?.id,
      )
      .map(data => ({
        ...data,
        id: data.id,
        valorTarifa: formattedMoney(parseFloat(data?.valorTarifa)),
        valorCusto: formattedMoney(parseFloat(data?.valorCusto)),
      }));

    setRows(rows2);
  }, []);

  const handleSalvar = () => {
    setLoadButton(true);

    api_multipague_configuracao
      .put(
        `/segmento/${historyState?.segmentoTarifadorEvento?.segmentoTarifador?.id}/evento/${historyState?.segmentoTarifadorEvento?.id}/faixa/${historyState?.id}`,
        {
          descricao: segmentoTarifadorDefault.descricao,
          faixaFinal: Number(segmentoTarifadorDefault.faixaFinal),
          faixaInicial: Number(segmentoTarifadorDefault.faixaInicial),
          valorCusto: Number(segmentoTarifadorDefault?.valorCusto),
          valorTarifa: Number(segmentoTarifadorDefault?.valorTarifa),
        },
      )
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Informação editada com sucesso!', theme);
        history.push('/backoffice/evento-tarifador-editar');
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  return (
    <Wrapper
      backButton
      title={`Editar Faixa Tarifador`}
      crumb={[
        { link: '/backoffice', name: 'Home' },
        { name: 'Configurações' },
        { link: '/backoffice/tarifador', name: 'Tarifadores' },
        {
          link: '/backoffice/evento-tarifador-editar',
          name: 'Eventos Tarifadores',
        },
      ]}
    >
      <Box className={classes.boxContainer}>
        <Grid container style={{ padding: '0 0 20px' }}>
          <Grid item md={12}>
            <Typography variant="body1" style={{ fontSize: 15 }}>
              Segmento Tarifador:{' '}
            </Typography>
            <Typography
              style={{ fontWeight: '600', fontSize: 15, marginLeft: 2 }}
              variant="body1"
            >
              {
                historyState?.segmentoTarifadorEvento.segmentoTarifador
                  .descricao
              }
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontSize: 15 }}>
              Evento:{' '}
            </Typography>
            <Typography
              style={{ fontWeight: '600', fontSize: 15, marginLeft: 2 }}
              variant="body1"
            >
              {historyState?.segmentoTarifadorEvento.evento.descricao}
            </Typography>
          </Grid>
        </Grid>

        <Divider />
        <Grid container spacing={3} style={{ paddingTop: 25 }}>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Descricao"
              id="outlined-size-small"
              value={segmentoTarifadorDefault.descricao}
              onChange={event =>
                setSegmentoTarifadorDefault(prev => ({
                  ...prev,
                  descricao: event?.target?.value,
                }))
              }
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Faixa Inicial"
              id="outlined-size-small"
              value={segmentoTarifadorDefault.faixaInicial}
              onChange={event =>
                setSegmentoTarifadorDefault(prev => ({
                  ...prev,
                  faixaInicial: event?.target?.value,
                }))
              }
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Faixa Final"
              id="outlined-size-small"
              value={segmentoTarifadorDefault.faixaFinal}
              onChange={event =>
                setSegmentoTarifadorDefault(prev => ({
                  ...prev,
                  faixaFinal: event?.target?.value,
                }))
              }
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CurrencyTextField
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              label="Valor Tarifa"
              value={segmentoTarifadorDefault.valorTarifa}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) =>
                setSegmentoTarifadorDefault(prev => ({
                  ...prev,
                  valorTarifa: value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CurrencyTextField
              fullWidth
              variant="outlined"
              size="small"
              label="Valor Custo"
              decimalCharacter=","
              digitGroupSeparator="."
              InputLabelProps={{
                shrink: true,
              }}
              currencySymbol="R$"
              outputFormat="string"
              value={segmentoTarifadorDefault.valorCusto}
              onChange={(event, value) =>
                setSegmentoTarifadorDefault(prev => ({
                  ...prev,
                  valorCusto: value,
                }))
              }
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <LoadingBtn
            variant="contained"
            color="disabled"
            width="115"
            title={'Voltar'}
            onClick={() => {
              history.push('/backoffice/evento-tarifador-editar');
            }}
            startIcon={<MdArrowBack color="background" />}
          >
            Voltar
          </LoadingBtn>
          <LoadingBtn
            variant="contained"
            color="primary"
            width="115"
            title={'Salvar'}
            isLoading={loadButton}
            startIcon={!loadButton && <MdSave color="background" />}
            onClick={() => {
              !loadButton && handleSalvar();
            }}
          >
            Salvar
          </LoadingBtn>
        </div>
      </Box>
    </Wrapper>
  );
}
