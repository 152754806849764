import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box as MuiBox,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { MdAdd, MdArrowBack, MdSave } from 'react-icons/md';
import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import DataGrid from '../../../components/DataGrid';
import {
  getEventsService,
  editEventsService,
  deleteEventService,
} from '../services';
import { useStyles } from '../styles';
import PlusButton from '../../../components/Buttons/PlusButton';
import ModalConfirm from '../../../components/ModalConfirm';

export default function EdicaoServicos() {
  const history = useHistory();
  const historyState = JSON.parse(localStorage.getItem('servicos'));
  const { cadastroTermo } = history?.location?.state || false;
  const theme = useTheme();
  const classes = useStyles();
  const [loadButton, setLoadButton] = useState(false);
  const [loadingDatagrid, setLoadingDatagrid] = useState(false);
  const [openModalDeleteEvento, setOpenModalDeleteEvento] = useState({
    open: null,
    id: '',
    evento: '',
    service: '',
  });
  const [cadastroServico, setCadastroServico] = useState({
    codigo: historyState?.id,
    descricao: historyState?.descricao,
  });
  const [rows, setRows] = useState([]);

  const columns = [
    { field: 'id', headerName: 'Código', flex: 1, minWidth: 100 },
    { field: 'descricao', headerName: 'Descrição', flex: 3, minWidth: 200 },
    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Ações',
      type: 'text',
      flex: 1,
      renderCell: event => {
        return (
          <MuiBox display={'flex'} justifyContent={'space-between'}>
            <Button
              variant="outlined"
              style={{ marginRight: 5, borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                localStorage.setItem('codigo', event.row.id);
                localStorage.setItem('descricao', event.row.descricao);
                localStorage.setItem(
                  'identificadorEvento',
                  event.row.identificadorEvento,
                );
                localStorage.setItem('esteira', event.row.utilizaEsteira);
                localStorage.setItem('senha', event.row.utilizaSenha);
                localStorage.setItem('ativo', event.row.ativo);

                history.push('/backoffice/eventos-editar');
              }}
            >
              <EditIcon color={'primary'} size={20} />
            </Button>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                setOpenModalDeleteEvento({
                  open: true,
                  id: event.row.id,
                  evento: event.row.descricao,
                  service: cadastroServico.descricao,
                });
              }}
            >
              <DeleteIcon color={'primary'} size={18} />
            </Button>
          </MuiBox>
        );
      },
    },
  ];

  const fetchEventsService = () => {
    setLoadingDatagrid(true);
    getEventsService(cadastroServico.codigo)
      .then(data => {
        if (!!data) {
          const aquiTable = data.map(data => ({
            ...data,
            id: data.id,
            descricao: data.descricao,
          }));
          setRows(aquiTable);
        } else {
          setRows([]);
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        if (error.response?.status === 404) {
          setRows([]);
          return;
        }
        toastComponentError(
          error.response?.data?.mensagem ||
            'Não foi possível se comunicar com o servidor',
          theme,
        );
      })
      .finally(() => {
        setLoadingDatagrid(false);
      });
  };

  useEffect(() => {
    fetchEventsService();
  }, []);

  const handleSalvar = () => {
    setLoadButton(true);
    editEventsService(historyState?.id, cadastroServico)
      .then(data => {
        setLoadButton(false);
        toastComponentSuccess('Informação editada com sucesso!', theme);
        history.push('/backoffice/servicos');
      })
      .catch(error => {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const handleDeleteEventService = () => {
    setLoadButton(true);
    deleteEventService(historyState?.id, openModalDeleteEvento.id)
      .then(data => {
        setLoadButton(false);
        toastComponentSuccess('Evento deletado com sucesso do serviço!', theme);
        setOpenModalDeleteEvento({
          open: null,
          id: '',
          evento: '',
          service: '',
        });
        fetchEventsService();
      })
      .catch(error => {
        console.log(error);
        setOpenModalDeleteEvento({
          open: null,
          id: '',
          evento: '',
          service: '',
        });
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  return (
    <>
      <ModalConfirm
        confirmLabel="Deletar"
        descricao={
          <>
            Deseja deletar o evento <b>{openModalDeleteEvento.evento}</b>
            do serviço <b>{openModalDeleteEvento.service}?</b>
          </>
        }
        open={!!openModalDeleteEvento.open}
        onClose={() =>
          setOpenModalDeleteEvento({
            open: null,
            id: '',
            evento: '',
            service: '',
          })
        }
        onConfirm={() => handleDeleteEventService()}
      />
      <Wrapper
        backButton
        title={'Editar serviço'}
        crumb={[
          { link: '/backoffice', name: 'Home' },
          { name: 'Configurações' },
          { link: '/backoffice/servicos', name: 'Serviços' },
        ]}
      >
        <Box style={{ borderRadius: '20px', padding: 30 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={2} lg={2}>
              <TextField
                fullWidth
                label="Código"
                disabled
                id="outlined-size-small"
                value={cadastroServico.codigo}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                fullWidth
                label="Descrição"
                id="outlined-size-small"
                value={cadastroServico.descricao}
                onChange={event =>
                  setCadastroServico(prev => ({
                    ...prev,
                    descricao: event?.target?.value,
                  }))
                }
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item lg={12} style={{ paddingTop: 50 }}>
            <MuiBox display="flex" alignItems={'center'} pb={1}>
              <Typography
                variant="body1"
                component="h3"
                style={{ marginRight: 5 }}
              >
                Eventos
              </Typography>
              <PlusButton
                title={'Adicionar Eventos'}
                startIcon={<MdAdd color="background" />}
                onClick={() =>
                  history.push('/backoffice/evento-servico-cadastro')
                }
              />
            </MuiBox>
            <DataGrid
              columns={columns}
              rows={rows}
              loading={loadingDatagrid}
              autoHeight
              pageSize={10}
            />
          </Grid>
          <div className={classes.divButtonBackSave}>
            <LoadingBtn
              variant="contained"
              color="disabled"
              width="115"
              title={'Voltar'}
              onClick={() => {
                if (!!cadastroTermo) {
                  history.push('/backoffice/termos-cadastro', {
                    value: cadastroTermo,
                  });
                } else {
                  history.goBack();
                }
              }}
              startIcon={<MdArrowBack color="background" />}
            >
              Voltar
            </LoadingBtn>
            <LoadingBtn
              variant="contained"
              color="primary"
              width="115"
              title={'Salvar'}
              isLoading={loadButton}
              startIcon={!loadButton && <MdSave color="background" />}
              onClick={() => {
                !loadButton && handleSalvar();
              }}
            >
              Salvar
            </LoadingBtn>
          </div>
        </Box>
      </Wrapper>
    </>
  );
}
