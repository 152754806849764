import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { FaSave } from 'react-icons/fa';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import { MdArrowBack, MdAdd } from 'react-icons/md';
import {
  Typography,
  Tooltip,
  Button,
  Box as MuiBox,
  alpha,
  Backdrop,
  Modal,
  Fade,
  Divider,
  IconButton,
} from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import DataGrid from '../../../components/DataGrid';
import formattedMoney from '../../../util/FormatteMoney';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../../services/api';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import { Cancel } from '@material-ui/icons';
import Loading from '../../../components/Loading';
import ModalConfirm from '../../../components/ModalConfirm';

const useStyles = makeStyles(theme => ({
  grid: {
    marginBottom: '20px',
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
    minWidth: 120,
  },
  btnAdd: {
    width: '25px',
    height: '25px',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: alpha(theme.palette.primary.dark, 0.8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: alpha(
      theme.palette.appBar[theme.palette.type].bgMenu,
      0.7,
    ),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '20px',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: 0,
  },
  content: {
    height: '100px',
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '10px',
    borderTop: 'solid 1px #e1e1e1',
    padding: '1rem',
  },
}));

export default function AssociacaoContasEditar() {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [loadButton, setLoadButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const [selecionarConta, setSelecionarConta] = useState([]);

  const [faixasRows, setFaixasRows] = useState([]);

  const [descricao, setDescricao] = useState(
    localStorage.getItem('ass-contas-consulta-descricao'),
  );
  const [valorMinimo, setValorMinimo] = useState(
    localStorage.getItem('ass-contas-consulta-vlrMinimo') / 100,
  );
  const [valorMaximo, setValorMaximo] = useState(
    localStorage.getItem('ass-contas-consulta-vlrMaximo') / 100,
  );
  const [valorTarifa, setValorTarifa] = useState(
    localStorage.getItem('ass-contas-consulta-vlrTarifa'),
  );
  const [valorCusto, setCusto] = useState(
    localStorage.getItem('ass-contas-consulta-vlrCusto'),
  );

  const [codigo, setCodigo] = useState(
    localStorage.getItem('ass-contas-consulta-codigo'),
  );
  const [codigoEvento, setCodigoEvento] = useState(
    localStorage.getItem('ass-contas-consulta-codigoEvento'),
  );

  const [codigoClienteConta, setCodigoClienteConta] = useState(
    localStorage.getItem('ass-contas-consulta-clienteConta'),
  );
  const [esteira, setEsteira] = React.useState(
    JSON.parse(localStorage.getItem('ass-contas-consulta-esteira')),
  );

  const [modalApagarFaixa, setModalApagarFaixa] = React.useState(null);

  const novoCadastro = {
    codigoClienteConta: Number(codigoClienteConta),
    codigoEvento: codigoEvento,
    //faixaTarifa: segmento,
    utilizaEsteira: esteira,
    valorMaximo: Number(valorMaximo),
    valorMinimo: Number(valorMinimo),
    valorTarifa: Number(valorTarifa),
    valorCusto: Number(valorCusto),
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  useEffect(() => {
    api_multipague_configuracao
      .get(`/tipoConta`)
      .then(response => {
        setSelecionarConta(response.data);
      })
      .catch(error => {
        console.log(error);
      });

    api_multipague_configuracao
      .get(`/eventoClienteConta/${codigo}/faixas`)
      .then(({ data }) => {
        setFaixasRows(data);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .put(`/eventoClienteConta/${codigo}`, novoCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Dados editados com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/associacao-contas-consulta');
        }, 3000);
      })
      .catch(function (error) {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };
  const handleCodigo = event => {
    setCodigo(event.target.value);
  };
  const handleEsteira = event => {
    setEsteira(event.target.checked);
  };
  const handleDescricao = event => {
    setDescricao(event.target.value);
  };

  const handleApagarFaixa = row => {
    setLoadingPage(true);

    setModalApagarFaixa(null);

    api_multipague_configuracao
      .delete(`/eventoClienteConta/${codigo}/faixas/${row?.id}`)
      .then(function (response) {
        setLoadingPage(false);
        toastComponentSuccess('Faixa apagada com sucesso!', theme);
        setFaixasRows(old => old.filter(item => item.id !== row?.id));
      })
      .catch(function (error) {
        setLoadingPage(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const columns = [
    { field: 'id', headerName: 'Código', minWidth: 50 },
    { field: 'descricao', flex: 1, headerName: 'Descrição', minWidth: 180 },
    {
      field: 'faixaInicial',
      headerName: 'Faixa Inicial',
      minWidth: 120,
    },
    {
      field: 'faixaFinal',
      headerName: 'Faixa Final',
      minWidth: 120,
    },
    {
      field: 'valorTarifa',
      headerName: 'Valor Tarifa',
      minWidth: 120,
      renderCell: value => {
        return <p>{formattedMoney(parseFloat(value.row.valorTarifa))} </p>;
      },
    },
    {
      field: 'valorCusto',
      headerName: 'Valor Custo',
      minWidth: 120,
      renderCell: value => {
        return <p>{formattedMoney(parseFloat(value.row.valorCusto))} </p>;
      },
    },
    {
      field: 'editar',
      minWidth: 150,
      headerName: 'Ação',
      type: 'text',
      renderCell: value => {
        return (
          <>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={e => {
                e.stopPropagation();
                history.push(
                  `/backoffice/associacao-contas-faixas/${value.row.id}`,
                  { descricao, codigo, faixa: value.row },
                );
              }}
            >
              <EditIcon color={theme.palette.primary.main} size={20} />
            </Button>

            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={e => {
                e.stopPropagation();
                setModalApagarFaixa(value.row);
              }}
            >
              <DeleteIcon color={theme.palette.error.main} size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Wrapper
      backButton
      title="Editar"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        {
          link: '/backoffice/associacao-contas-lista',
          name: 'Associação de Contas',
        },
        { link: '/backoffice/associacao-contas-consulta', name: 'Consulta' },
      ]}
    >
      <Box style={{ borderRadius: '20px' }}>
        <div style={{ height: '1rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              id="outlined-size-small"
              onChange={handleCodigo}
              value={codigo}
              label="Código"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              id="outlined-size-small"
              value={descricao}
              label="Descrição"
              onChange={handleDescricao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              fullWidth
              variant="outlined"
              label="Valor Mínimo"
              size="small"
              value={valorMinimo}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) => setValorMinimo(value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              fullWidth
              variant="outlined"
              size="small"
              label="Valor Máximo"
              value={valorMaximo}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) => setValorMaximo(value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormControl
              variant="outlined"
              size="small"
              fullwidth
              className={classes.formControl}
            >
              <CurrencyTextField
                fullWidth
                variant="outlined"
                label={'Valor Custo'}
                size="small"
                value={valorCusto}
                decimalCharacter=","
                digitGroupSeparator="."
                currencySymbol="R$"
                outputFormat="string"
                onChange={(event, value) => setCusto(value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              fullWidth
              variant="outlined"
              size="small"
              label="Valor Tarifa"
              value={valorTarifa}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) => setValorTarifa(value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <CustomSwitch
              checked={esteira}
              onChange={handleEsteira}
              color="primary"
              label="Esteira"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <FormLabel component="legend">Esteira</FormLabel>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={12} md={12}></Grid>
          <div
            style={{
              margin: '10px',
              width: '100%',
              marginTop: '1rem',
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <LoadingBtn
              variant="contained"
              color="disabled"
              width="115"
              title={'Voltar'}
              startIcon={<MdArrowBack color="background" />}
              onClick={() => {
                history.go(-1);
              }}
            >
              Voltar
            </LoadingBtn>
            <LoadingBtn
              variant="contained"
              title={'Salvar'}
              color="primary"
              width="115"
              isLoading={loadButton}
              startIcon={
                !loadButton && <FaSave size="1em" color="background" />
              }
              onClick={() => {
                handleSalvar();
              }}
            >
              Salvar
            </LoadingBtn>
          </div>
        </Grid>

        <Grid item lg={12} style={{ paddingTop: 50 }}>
          <MuiBox display="flex">
            <Typography
              variant="body1"
              component="h3"
              style={{ paddingBottom: 10, marginRight: 10 }}
            >
              Faixas de Tarifas
            </Typography>

            <Tooltip placement="left" title={'Adicionar Faixas de Tarifas'}>
              <figure
                className={classes.btnAdd}
                onClick={() =>
                  history.push('/backoffice/associacao-contas-faixas', {
                    descricao,
                    codigo,
                  })
                }
              >
                <MdAdd
                  size={20}
                  style={{ margin: 'auto' }}
                  color={'background'}
                />
              </figure>
            </Tooltip>
          </MuiBox>
          <DataGrid
            columns={columns}
            rows={faixasRows}
            loading={false}
            autoHeight
            pageSize={10}
          />
        </Grid>
      </Box>
      <ModalConfirm
        descricao={
          <>
            Deseja apagar a faixa <b>{modalApagarFaixa?.descricao}?</b>
          </>
        }
        open={!!modalApagarFaixa}
        onClose={() => setModalApagarFaixa(null)}
        onConfirm={() => handleApagarFaixa(modalApagarFaixa)}
      />

      <Loading show={loadingPage} />
    </Wrapper>
  );
}
