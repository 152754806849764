import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import { api_multipague_configuracao_aviso } from '../../../services/api';
import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import { MdImage, MdSave } from 'react-icons/md';
import { useStyles } from '../styles';

export default function AvisosLista() {
  const history = useHistory();
  const date = new Date();
  const theme = useTheme();
  const classes = useStyles();
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);

  const [dataVigenciaDe, setDataVigenciaDe] = useState(
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      'YYYY-MM-DD',
    ),
  );
  const [dataVigenciaAte, setDataVigenciaAte] = useState(
    moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
      'YYYY-MM-DD',
    ),
  );
  const [direcionamento, setDirecionamento] = useState();
  const [observacao, setObservacao] = useState();

  const [loadButton, setLoadButton] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [enviaArquivo, setEnviaArquivo] = useState([]);
  const [arquivo, setArquivo] = useState(null);
  const [situacao, setSituacao] = useState();

  const novoAviso = {
    ativo: situacao == 'ATIVADO' ? true : false,
    vigenciaInicio: dataVigenciaDe,
    vigenciaFim: dataVigenciaAte,
    observacao: observacao,
    urlRedirecionamento: direcionamento,
    caminhoStorage: imageUrl,
  };

  const handleSituacao = event => {
    setSituacao(event.target.value);
  };

  useEffect(() => {
    if (arquivo) {
      setImageUrl(window.URL.createObjectURL(arquivo));
    }
    console.log('ARQUIVO: ', arquivo);
  }, [arquivo]);

  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URL = `/avisos`;

  function salvar() {
    setLoadButton(true);
    const formData = new FormData();
    formData.append('file', arquivo);
    formData.append('dataVigenciaInicio', dataVigenciaDe);
    formData.append('dataVigenciaFim', dataVigenciaAte);
    formData.append('observacao', observacao);
    formData.append('urlRedirecionamento', direcionamento);

    api_multipague_configuracao_aviso
      .post(URL, formData)
      .then(function (response) {
        setLoadButton(false);
        toastComponentSuccess('Novo anúncio cadastrado!', theme);
        setTimeout(function () {
          history.push('/backoffice/avisos-lista');
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }

  function guardarArquivo(e) {
    setArquivo(e.target.files[0]);
    setEnviaArquivo(e.target.files[0]);
  }

  return (
    <Wrapper
      backButton
      title="Cadastro de Aviso"
      crumb={[{ link: '/backoffice', name: 'Home' }]}
    >
      <Box className={classes.wrapperBox}>
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
          <Grid item xs={12} md={3} lg={3}>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
            >
              <InputLabel
                style={{
                  backgroundColor: theme.palette.background.paper,
                  padding: '0 5px',
                }}
                shrink
                id="demo-simple-select-outlined-label"
              >
                Ativo ?
              </InputLabel>
              <Select
                fullWidth
                label="Situação"
                id="outlined-size-small"
                variant="outlined"
                style={{ borderRadius: '20px' }}
                size="small"
                onChange={handleSituacao}
              >
                <MenuItem value="ATIVADO">ATIVADO</MenuItem>
                <MenuItem value="DESATIVADO">DESATIVADO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Data Vigência Início"
              id="outlined-size-small"
              style={{ borderRadius: '20px' }}
              value={dataVigenciaDe}
              onChange={e => setDataVigenciaDe(e.target.value)}
              variant="outlined"
              size="small"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Data Vigência Fim"
              id="outlined-size-small"
              value={dataVigenciaAte}
              onChange={e => setDataVigenciaAte(e.target.value)}
              variant="outlined"
              size="small"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="URL e Direcionamento"
              id="outlined-size-small"
              onChange={e => {
                setDirecionamento(e.target.value);
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              id="outlined-multiline-static"
              label="Observação"
              multiline
              fullWidth
              rows={4}
              onChange={e => {
                setObservacao(e.target.value);
              }}
              variant="outlined"
              InputProps={{
                style: {
                  borderRadius: '20px',
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}></Grid>
          {imageUrl && arquivo && (
            <Grid item xs={12} md={4} lg={4}>
              <div>Previsão da imagem:</div>
              <img src={imageUrl} alt={arquivo.name} width="100%" />
            </Grid>
          )}
          <Grid item xs={12} md={4} lg={4}></Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={e => guardarArquivo(e)}
            />
            <label htmlFor="contained-button-file">
              <LoadingBtn
                style={{
                  borderRadius: '20px',
                  boxShadow: `${alpha(
                    theme.palette.primary.dark,
                    0.2,
                  )} 0px 0px 45px -15px`,
                }}
                startIcon={<MdImage size={15} color="background" />}
                title={'Upload Imagem'}
                variant="contained"
                color="primary"
                component="span"
              ></LoadingBtn>
            </label>

            {arquivo && (
              <LoadingBtn
                variant="contained"
                color="primary"
                title={'Salvar'}
                startIcon={<MdSave color="background" size={15} />}
                style={{ marginLeft: '20px' }}
                isLoading={loadButton}
                onClick={() => salvar()}
              ></LoadingBtn>
            )}
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
}
