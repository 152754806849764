import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { api_multipague_configuracao } from '../../../services/api';
import DataGridWrapper from '../../../components/DataGrid/DataGridWrapper';
import { toastComponentError } from '../../../components/Toast';
import { Button, Tooltip, useTheme } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

export default function DataGridDemo() {
  const history = useHistory();

  const columns = [
    { field: 'id', headerName: 'Código', flex: 1, minWidth: 100 },
    { field: 'nome', headerName: 'Nome', flex: 3, minWidth: 200 },
    { field: 'conta', headerName: 'Conta', flex: 3, minWidth: 200 },
    { field: 'padrao', headerName: 'Favorecido Padrão', flex: 3, minWidth: 200,
    renderCell: event => {
      return !!event.row.padrao ? 
        <Tooltip title={"Favorecido Padrão"}>
           <CheckCircle style={{color:useTheme().palette.primary.main}} />
        </Tooltip> : <CheckCircle color="disabled" />
      ;
    }, },

    {
      field: 'editar',
      minWidth: 100,
      headerName: 'Editar',
      type: 'text',
      flex: 1,
      renderCell: event => {
        return (
          <>
            <Button
              variant="outlined"
              style={{ borderRadius: '20px' }}
              color="primary"
              onClick={() => {
                handleEditar(event.row);
              }}
            >
              <EditIcon color={useTheme().palette.primary.main} size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [loadButton, setLoadButton] = useState(true);

  function novoRegistro() {
    history.push('/backoffice/favorecido-tarifador-form');
  }
  const USER_TOKEN = localStorage.getItem('tkn-access');
  const AuthStr = 'Bearer '.concat(USER_TOKEN);
  const URL = `/favorecido-tarifador`;

  useEffect(() => {
    setLoadButton(true);
    api_multipague_configuracao
      .get(URL)
      .then(response => {
        setLoadButton(false);
        const monitorFilter = response.data;
        if (monitorFilter.length) { 
          setRows(monitorFilter.map(item=>({
            nome: item?.favorecido?.nome,
            conta: `Nº: ${item?.favorecido?.conta}-${item?.favorecido?.digitoConta} Ag.: ${item?.favorecido?.agencia}`,
            padrao: Boolean(item?.favorecidoPadrao),
            ...item,
          })));
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }, []);

  const handleEditar = row => {
    history.push('/backoffice/favorecido-tarifador-form/'+row?.id,{...row});
  };

  return (
    <DataGridWrapper
      minHeight="600px"
      rows={rows}
      backButton
      title="Favorecido Tarifador"
      crumb={[
        { link: '/backoffice/new-backoffice', name: 'Home' },
        { name: 'Configurações' },
      ]}
      newRegister={novoRegistro}
      columns={columns}
      loading={loadButton}
      pageSize={10}
    />
  );
}
