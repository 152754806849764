import React, { useEffect, useState, useRef } from 'react';
import { IoMdMailUnread } from 'react-icons/io';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { api_multipague_configuracao } from '../../services/api';
import InputText from '../../components/InputText';
import CPF from '../../components/CPF';
import Loading from '../../components/Loading';
import { maskCpfCnpj } from '../../util/mask';
import PersonIcon from '@material-ui/icons/Person';
import { ButtonTable, Checks } from './styles';
import { useHistory } from 'react-router-dom';
import { useTheme, Grid } from '@material-ui/core';
import DataGridWrapper from '../../components/DataGrid/DataGridWrapper';
import { Form } from '@unform/web';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';
import RenderActionsCell from '../../components/DataGrid/DataGridWrapper/Actions';

export const Usuarios = () => {
  const history = useHistory();
  const formRef = useRef(null);
  const theme = useTheme();
  const [openMenuActions, setOpenMenuActions] = useState();
  const [rowId, setRowId] = useState(null);

  useEffect(() => {
    const verifyMaster = localStorage.getItem('roles');
    const roles = verifyMaster?.includes('usuario_master');

    if (!roles) {
      history.push('/backoffice');
    }
  }, []);
  function handleEditUser(value) {
    localStorage.setItem('edicao_usuario_backoffice', JSON.stringify(value));
    history.push('/backoffice/edicao-usuarios');
  }
  const columnsTable = [
    { field: 'nome', headerName: 'Nome', flex: 3, minWidth: 200 },
    {
      field: 'login',
      headerName: 'CPF/CNPJ',
      type: 'string',
      flex: 3,
      minWidth: 150,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      type: 'string',
      minWidth: 200,
      flex: 3,
    },
    {
      field: 'snedemail',
      headerName: 'Ações',
      sortable: false,
      flex: 3,
      minWidth: 110,
      disableClickEventBubbling: true,
      renderCell: params => {
        return (
          <RenderActionsCell
            event={params}
            actionsComponent={
              <>
                <Tooltip title="Editar Usuário" aria-label="editar-usuario">
                  <ButtonTable
                    style={{ color: theme.palette.primary.main }}
                    onClick={() => {
                      handleEditUser(params.row);
                    }}
                  >
                    <PersonIcon size={24} color={theme.palette.primary.main} />
                  </ButtonTable>
                </Tooltip>
                <Tooltip title="Enviar E-mail" aria-label="send_email">
                  <ButtonTable
                    hasError={!!params.row.codigoErro}
                    onClick={() => {
                      sendEmail(params.row);
                    }}
                  >
                    <IoMdMailUnread
                      size={24}
                      color={theme.palette.primary.main}
                    />
                  </ButtonTable>
                </Tooltip>
                <Tooltip
                  title={
                    params.row.ativo
                      ? 'Bloquear Usuário'
                      : 'Desbloquear Usuário'
                  }
                  aria-label="block_user"
                >
                  <ButtonTable
                    style={{ marginLeft: '10px' }}
                    onClick={() => blockUser(params.row)}
                  >
                    <FormControlLabel
                      control={
                        <Switch color="primary" checked={!params.row.ativo} />
                      }
                    />
                  </ButtonTable>
                </Tooltip>
              </>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  const [rowsTable, setRowsTable] = useState([]);

  const [loadingTable, setLoadingTable] = useState(false);

  const [loadingScreen, setLoadingScreen] = useState(false);

  async function blockUser({ ativo, login }) {
    try {
      setLoadingScreen(true);
      const { data } = await api_multipague_configuracao.get(
        `/usuario/ativo/${!ativo}/${login.replace(/[^\d]/g, '')}`,
      );

      const formatted = rowsTable.map(val => ({
        ...val,
        ativo: val.login === login ? !val.ativo : val.ativo,
      }));

      setRowsTable(formatted);
      toastComponentSuccess(`Usuário ${ativo ? 'Bloqueado' : 'Desbloqueado'}`, theme);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
      console.log(err);
    } finally {
      setLoadingScreen(false);
    }
  }

  async function sendEmail({ login }) {
    try {
      setLoadingScreen(true);
      const { data } = await api_multipague_configuracao.post(
        `usuario/senha/definir/enviar-email?username=${login.replace(
          /[^\d]/g,
          '',
        )}`,
      );
      toastComponentSuccess(`E-mail enviado com sucesso`, theme);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
      console.log(err);
    } finally {
      setLoadingScreen(false);
    }
  }

  async function listarUsuarios() {
    try {
      setLoadingTable(true);
      const { data } = await api_multipague_configuracao.get(
        'usuario/login?master=true',
      );

      const formatted = data.map(usr => ({
        ...usr,
        nome: usr.nome,
        login: maskCpfCnpj(usr.login),
        email: usr.email,
        situacao: usr.ativo,
        id: usr.id,
      }));
      setRowsTable(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
      );
      console.log(err);
    } finally {
      setLoadingTable(false);
    }
  }
  async function filterUser(body) {
    try {
      setLoadingTable(true);
      const { data } = await api_multipague_configuracao.get(
        `usuario/login?master=true&nome=${body.nome}&username=${!!body.cpf ? body.cpf.replace(/[^\d]/g, '') : ''
        }`,
      );

      const formatted = data.map(usr => ({
        ...usr,
        nome: usr.nome,
        login: maskCpfCnpj(usr.login),
        email: usr.email,
        situacao: usr.ativo,
        id: usr.id,
      }));
      setRowsTable(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
      console.log(err);
    } finally {
      setLoadingTable(false);
    }
  }

  function novoRegistro() {
    history.push('/backoffice/usuario');
  }

  useEffect(() => {
    listarUsuarios();
  }, []);

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <InputText id="nome" name="nome" label="Nome" variant="outlined" />
        ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
      {
        html: () => <CPF name="cpf" id="cpf" variant="outlined" label="CPF" />,
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
    ],
    searchButton: {
      searchButton: () => filterUser(formRef?.current?.getData()),
    },
    cleanButton: {
      cleanButton: () => {
        formRef?.current?.setData({ nome: '', cpf: '' });
        filterUser(formRef?.current?.getData());
      },
    },
  };

  return (
    <>
      <Loading show={loadingScreen} />
      <Form ref={formRef} onSubmit={filterUser}>
        <DataGridWrapper
          backButton
          title="Usuários"
          crumb={[{ link: '/backoffice/new-backoffice', name: 'Home' }]}
          newRegister={novoRegistro}
          formData={filterInputs}
          minHeight={'600px'}
          rows={rowsTable}
          onRowClick={params => setRowId(params.id)}
          columns={columnsTable}
          pageSize={10}
          loading={loadingTable}
        />
      </Form>
    </>
  );
};
